import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
} from 'react';
import {
  Grid,
  Drawer,
  Fab,
  createMuiTheme,
} from '@material-ui/core';
import {
  func,
  shape,
  bool,
  arrayOf,
} from 'prop-types';
import Views from '@material-ui/icons/RemoveRedEye';
import ChatIcon from '@material-ui/icons/ChatBubble';
import CloseIcon from '@material-ui/icons/Close';
import { ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import Environment from './components/Environment';
import ModalPhotos from './components/ModalPhotos';
import DownloadApp from '../../components/CTA/DownloadApp';
import useInfiniteScroll from '../../utils/hooks/useInfiniteScroll';
import usePagination from './hooks/usePagination';
import GalleryModal from '../../components/GalleryModal';
import flowering from '../../assets/avatar-flowering.jpg';
import germination from '../../assets/avatar-germination.jpg';
import vegetative from '../../assets/avatar-vegetative.jpg';
import { initGA, sendAnalytics, sendPageView } from '../../utils/analytics';
import DiscussionBox from '../../components/DiscussionBox';
import config from '../../config';

const Gallery = lazy(() => import('../../components/Gallery'));
const Timeline = lazy(() => import('./components/Timeline'));

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

const Growlog = ({
  getGrowlog,
  clearGrowlog,
  growlogData,
  galleryPhotos,
  loading,
  match: {
    params: { id, image },
  },
  history,
}) => {
  const [modalPhotosData, setModalPhotosData] = useState(null);
  const [modalPhotosURL, setModalPhotosURL] = useState(null);
  const [showCTA, setshowDownloadApp] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll();
  const [events, moreResults] = usePagination(growlogData.events, isFetching, setIsFetching);
  const toggleMenu = () => setOpenMenu(!openMenu);

  useEffect(() => {
    initGA();
    sendPageView(`/growlog/${id}/`);
    getGrowlog(id);
    window.scrollTo(0, 0);

    return () => clearGrowlog();
  }, [clearGrowlog, getGrowlog, id]);

  useEffect(() => {
    if (events.length > 15) {
      setshowDownloadApp(true);
    }
  }, [events.length, isFetching]);

  useEffect(() => {
    if (showGallery) {
      sendAnalytics({
        category: 'growlog',
        action: 'open-gallery',
        treeName: growlogData.tree.name,
        totalImages: galleryPhotos.length,
      });
    }
  }, [galleryPhotos.length, growlogData.tree.name, showGallery]);


  useEffect(() => {
    if (modalPhotosData) {
      sendAnalytics({
        category: 'growlog',
        action: 'open-photo',
        treeName: growlogData.tree.name,
      });
    }
  }, [growlogData.tree.name, modalPhotosData]);

  useEffect(() => {
    if (image && galleryPhotos.length && !modalPhotosData && !modalPhotosURL) {
      const urlId = atob(image);
      const currentIndex = galleryPhotos.reverse().findIndex(({ id: imageId }) => imageId === urlId);

      setModalPhotosURL({
        images: galleryPhotos,
        currentIndex,
      });
    }
  }, [image, galleryPhotos, modalPhotosData, modalPhotosURL]);

  const hideModalPhotos = () => {
    history.replace(`/growlog/${id}/`);
    setModalPhotosData(null);
    setModalPhotosURL(null);
  };

  const hideModalGallery = () => setShowGallery(false);

  const hideDownloadApp = () => {
    localStorage.setItem('lastCTAShow', moment());
    setshowDownloadApp(false);
  };

  const renderCTA = () => {
    const lastCTAShow = localStorage.getItem('lastCTAShow');
    const isSameDate = moment(lastCTAShow).isSame(moment(), 'day');

    if (showCTA && !isSameDate) {
      return <DownloadApp hideDownloadApp={hideDownloadApp} />;
    }

    return null;
  };

  const getTreeAvatar = () => {
    const { tree } = growlogData;
    const treeAvatar = (tree.avatar && tree.avatar.source === 'PhotoSourceEnum.url') ? tree.avatar.path : null;

    const stagesAvatar = {
      'TreeStageEnum.seedling': germination,
      'TreeStageEnum.rooting': germination,
      'TreeStageEnum.germination': germination,
      'TreeStageEnum.vegetative': vegetative,
      'TreeStageEnum.flowering': flowering,
      'TreeStageEnum.drying': flowering,
      'TreeStageEnum.curing': flowering,
    };

    return treeAvatar || stagesAvatar[growlogData.tree.stage];
  };

  const getURL = () => {
    const baseUrl = window.location.origin;
    const pathParths = window.location.pathname.split('/');

    return `${baseUrl}/growlog/${pathParths[2]}`;
  };

  if (loading) {
    return (
      <div className={`load${loading ? '' : ' loaded'}`}>
        <div className="load__icon-wrap">
          <svg className="load__icon">
            <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="banner">
          <img className="banner-image" src={getTreeAvatar()} alt="banner" />
          <Grid container justify="space-between" className="banner-container-title container">
            <h1>
              <i className="icon-flowering" />
              {growlogData.tree.name}
            </h1>
            <h1 className="views">
              {growlogData.views}
              <Views className="views-icon" />
            </h1>
          </Grid>
        </div>
        <Grid container justify="center" className="growlog-container">
          <div className="container">
            <Grid container>
              <Grid item xs={12} md={4} className="environment-content">
                <Environment
                  tree={growlogData.tree}
                  lengthUnit={growlogData.user ? growlogData.user.lengthUnit : 'cm'}
                  environment={growlogData.environment}
                  strain={growlogData.strain}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Suspense fallback={<h1>Loading…</h1>}>
                  {
                    !!galleryPhotos.length && (
                      <Gallery
                        photos={galleryPhotos.slice(0, 10)}
                        totalPhotos={galleryPhotos.length}
                        showModal={setShowGallery}
                      />
                    )
                  }
                </Suspense>
                <Suspense fallback={<h1>Loading…</h1>}>
                  <Timeline
                    setModalPhotosData={setModalPhotosData}
                    events={growlogData.events}
                    eventsArray={events}
                    treeCreateDate={Object.keys(growlogData.events)[0]}
                    history={history}
                  />
                </Suspense>
                {
                  moreResults && isFetching ? (
                    <div className="load load__mobile">
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                      </div>
                    </div>
                  ) : null
                }
              </Grid>
            </Grid>
          </div>
        </Grid>
        {
          (modalPhotosData || modalPhotosURL) && (
            <ModalPhotos
              hideModal={hideModalPhotos}
              tree={growlogData.tree}
              lengthUnit={growlogData.user ? growlogData.user.lengthUnit : null}
              treeCreateDate={Object.keys(growlogData.events)[0]}
              environment={growlogData.environment}
              modalData={modalPhotosURL || modalPhotosData}
            />
          )
        }
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          aria-label="add"
          className="fab-comments"
          onClick={toggleMenu}
        >
          <ChatIcon />
          See comments
        </Fab>
        <Drawer
          variant="temporary"
          anchor="right"
          open={openMenu}
          onClose={toggleMenu}
          classes={{
            paper: 'comments-drawer',
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Grid
            direction="row"
            onClick={toggleMenu}
            className="comments-header"
          >
            <CloseIcon />
            CLOSE COMMENTS
          </Grid>
          <DiscussionBox discourseUrl={config.DISCOURSE_URL} discourseEmbedUrl={getURL()} />
        </Drawer>
        <GalleryModal
          treeName={growlogData.tree.name}
          showGallery={showGallery}
          hideModal={hideModalGallery}
          photos={galleryPhotos}
          setModalPhotosData={setModalPhotosData}
        />
        { renderCTA() }
      </ThemeProvider>
    </>
  );
};

Growlog.propTypes = {
  getGrowlog: func.isRequired,
  clearGrowlog: func.isRequired,
  growlogData: shape({}).isRequired,
  galleryPhotos: arrayOf(shape({})).isRequired,
  match: shape({}).isRequired,
  history: shape({}).isRequired,
  loading: bool.isRequired,
};

export default Growlog;
