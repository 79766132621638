import React, {
  useState,
} from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { hot } from 'react-hot-loader';

import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import firebase from 'firebase';
import Router from './Router';
import store, { history } from './redux/store';
import './scss/app.scss';
import config from './config';

const App = () => {
  const [loading, setLoading] = useState(false);

  window.addEventListener('load', () => {
    firebase.initializeApp(config.firebaseConfig);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  });

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {loading && (
          <div className={`load${loading ? '' : ' loaded'}`}>
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
          </div>
        )}
        {!loading && (
          <Router />
        )}
      </ConnectedRouter>
    </Provider>
  );
};


export default hot(module)(App);
