import React from 'react';
import {
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Collapse,
  Hidden,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  bool, func, arrayOf, shape,
} from 'prop-types';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import Dropdown from '../../../../components/Dropdown';
import filters from '../../../../utils/filters';

const Filters = ({
  isMobile,
  openFilters,
  handleOpenFilters,
  handleFilters,
  selectedFilters,
  clearFilters,
}) => {
  const getFilterLabel = (filterName, filterType, selectedLabel) => {
    const filterCount = selectedFilters.filter((filter) => filter.includes(filterType)).length;

    return filterCount ? <strong>{`${selectedLabel} - ${filterCount}`}</strong> : filterName;
  };

  return (
    <Grid container>
      <Hidden mdUp>
        <Grid item xs={12} md={2}>
          <Box
            display="flex"
            alignItems="center"
            className="growlogs-content-title"
            onClick={handleOpenFilters}
          >
            <h4>
              Filter by:
              {
                  selectedFilters && !!selectedFilters.length && (
                    <span
                      role="button"
                      tabIndex="0"
                      className="growlogs-content-title-clear"
                      onClick={clearFilters}
                      onKeyPress={clearFilters}
                    >
                      Clear filters
                      <CloseIcon className="growlogs-content-title-clear-icon" />
                    </span>
                  )
                }
            </h4>
            {isMobile && (openFilters ? <ExpandLess /> : <ExpandMore />)}
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        <Collapse in={isMobile ? openFilters : true}>
          <Grid container>
            <Hidden smDown>
              <Grid item xs={12} md={4} className="growlogs-content-title">
                <h4>Filter by</h4>
                {
                  selectedFilters && !!selectedFilters.length && (
                    <div
                      role="button"
                      tabIndex="0"
                      className="growlogs-content-title-clear"
                      onClick={clearFilters}
                      onKeyPress={clearFilters}
                    >
                      Clear filters
                      <CloseIcon className="growlogs-content-title-clear-icon" />
                    </div>
                  )
                }
              </Grid>
            </Hidden>
            {
              filters.map(({
                name, selectedLabel, type, options,
              }) => (
                <Grid item xs={12} md={2}>
                  <div className="filter-button">
                    <Dropdown name={getFilterLabel(name, type, selectedLabel)}>
                      {
                        options.map(({ label, value }) => (
                          <FormControlLabel
                            className="dropdown__options_group__item"
                            control={(
                              <Checkbox
                                checked={selectedFilters.includes(value)}
                                onClick={handleFilters}
                                icon={<CheckBoxOutlineBlankIcon className="filter-checkbox-icon" />}
                                checkedIcon={<CheckBoxIcon className="filter-checkbox-icon" />}
                                className="filter-modal-checkbox"
                              />
                            )}
                            label={label}
                            value={value}
                          />
                        ))
                      }
                    </Dropdown>
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

Filters.propTypes = {
  openFilters: bool.isRequired,
  isMobile: bool.isRequired,
  handleOpenFilters: func.isRequired,
  handleFilters: func.isRequired,
  clearFilters: func.isRequired,
  selectedFilters: arrayOf(shape({})).isRequired,
};

export default Filters;
