import {
  SET_GROWLOG_BY_ID,
  SET_GROWLOGS,
  CLEAR_GROWLOG,
  GET_GROWLOGS,
  CLEAR_GROWLOGS_LIST,
} from '../actionTypes';

const initialState = {
  growlogData: {
    tree: {
      avatar: { path: '' },
      strain: { name: '' },
      stages: {},
    },
    environment: {
      type: '',
    },
    events: {},
  },
  growlogsList: {
    rows: [],
    page: 0,
    loading: false,
  },
};

const growlogReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_GROWLOG_BY_ID:
      return {
        ...state,
        growlogData: payload,
      };

    case CLEAR_GROWLOG:
      return {
        ...state,
        growlogData: initialState.growlogData,
      };

    case GET_GROWLOGS:
      return {
        ...state,
        growlogsList: {
          ...state.growlogsList,
          loading: true,
        },
      };

    case SET_GROWLOGS:
      return {
        ...state,
        growlogsList: {
          ...state.growlogsList,
          ...payload,
          rows: state.growlogsList.rows.concat(payload.rows),
          loading: false,
        },
      };

    case CLEAR_GROWLOGS_LIST:
      return {
        ...state,
        growlogsList: initialState.growlogsList,
      };

    default:
      return state;
  }
};

export default growlogReducer;
