import Cookies from 'universal-cookie';
import firebase from 'firebase';
import config from '../config';

const postData = (url = '', data = {}) => fetch(config.FUNCTIONS_URL + url, {
  method: 'POST',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'include', // SUPER IMPORTANT, requires cors config (included)
  headers: {
    'Content-Type': 'application/json',
  },
  redirect: 'follow',
  body: JSON.stringify(data),
})
  .then((response) => response.json());
const postForm = (url = '', data = {}) => {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const name in data) {
    if (name === 'avatar') {
      formData.append(name, data[name][0]);
    } else if (name !== 'password') {
      formData.append(name, data[name]);
    }
  }

  return fetch(config.FUNCTIONS_URL + url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include', // SUPER IMPORTANT, requires cors config (included)
    headers: {
    },
    redirect: 'follow',
    body: formData,
  })
    .then((response) => response.json());
};
const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export default {
  loginGoogle: () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => firebase
        .auth()
        .signInWithPopup(provider)
        .then((credential) => credential.user.getIdToken(true).then(async (token) => postData('sso/session', { idToken: token })
          .then((data) => {
            console.log(JSON.stringify(data));
            const cookies = new Cookies();
            return cookies.set('session', data.cookie, { path: '/', maxAge: data.maxAge });
          }) // JSON-string from `response.json()` call
          .catch((error) => console.error(error)))));
  },
  loginPassword: async (username, password) => {
    let email = username;
    if (!validateEmail(username)) {
      try {
        const realEmail = await postData('sso/email', { username, password });
        email = realEmail.email;
      } catch (err) {
        console.error('Error fetching user email');
      }
      if (!email.includes('@')) {
        email += '@growithjane.usertoken';
      }
    }
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const credential = await firebase.auth().signInWithEmailAndPassword(email, password);
    const token = await credential.user.getIdToken(true);
    return postData('sso/session', { idToken: token })
      .then((data) => {
        console.log(JSON.stringify(data));
        const cookies = new Cookies();
        return cookies.set('session', data.cookie, { path: '/', maxAge: data.maxAge });
      }) // JSON-string from `response.json()` call
      .catch((error) => console.error(error));
  },
  onBoarding: async (values) => postForm('sso/onboarding', values),
};
