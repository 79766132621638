const configDev = {
  ENDPOINT_URL: process.env.REACT_APP_ENDPOINT_URL ?? 'https://growlog.growithjane.com/api/',
  FUNCTIONS_URL: 'https://us-central1-jane-dev-9a747.cloudfunctions.net/',
  DISCOURSE_URL: 'https://discourse.growithjane.com/',
  firebaseConfig: {
    apiKey: 'AIzaSyAebAjpUp8CFNQuUtqKJjPPQMPBxcLbOS0',
    authDomain: 'jane-dev-9a747.firebaseapp.com',
    databaseURL: 'https://jane-dev-9a747.firebaseio.com',
    projectId: 'jane-dev-9a747',
    storageBucket: 'jane-dev-9a747.appspot.com',
    messagingSenderId: '62061938367',
    appId: '1:62061938367:web:d43dfa339ac9d57a88a80a',
    measurementId: 'G-EPEMPSZRM1',
  },
};


const configProd = {
  ENDPOINT_URL: process.env.REACT_APP_ENDPOINT_URL ?? 'https://growlog.growithjane.com/api/',
  FUNCTIONS_URL: 'https://firebase.growithjane.com/',
  DISCOURSE_URL: 'https://social.growithjane.com/',
  firebaseConfig: {
    apiKey: 'AIzaSyD5lIam2OmqyDBAT0LDTKSRdu5I7xDnL1g',
    authDomain: 'jane-14027.firebaseapp.com',
    databaseURL: 'https://jane-14027.firebaseio.com',
    projectId: 'jane-14027',
    storageBucket: 'jane-14027.appspot.com',
    messagingSenderId: '164029308690',
    appId: '1:164029308690:web:5f05e7a61de534c8',
  },
};

console.log('ENV', process.env);

export default process.env.SERVER_ENV === 'production' ? configProd : configDev;
