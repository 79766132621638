import { useState, useEffect } from 'react';

const useInfiniteScroll = () => {
  const [isFetching, setIsFetching] = useState();

  const handleScroll = () => {
    const documentOffsetHeight = document.documentElement.offsetHeight - (window.innerWidth < 600 ? 1000 : 600);
    const windowScroll = window.innerHeight + document.documentElement.scrollTop;

    if (windowScroll < documentOffsetHeight && !isFetching) return;
    setIsFetching(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return [isFetching, setIsFetching];
};

export default useInfiniteScroll;
