import React from 'react';
import {
  Grid,
  Box,
  Hidden,
} from '@material-ui/core';
import Views from '@material-ui/icons/RemoveRedEye';
import {
  shape,
  number,
  string,
  func,
} from 'prop-types';
import moment from 'moment';
import Icon from '../Icon';
import { stageColors, stagesTypes, environmentType } from '../../utils/constants';
import { getThumbnail } from '../../utils/growlog';

import flowering from '../../assets/avatar-flowering.jpg';
import germination from '../../assets/avatar-germination.jpg';
import vegetative from '../../assets/avatar-vegetative.jpg';

const GrowlogCard = ({
  tree,
  environment,
  strain,
  views,
  age,
  lastUpdate,
  onClick,
}) => {
  const getTreeAvatar = () => {
    const treeAvatar = (tree.avatar && tree.avatar.source === 'PhotoSourceEnum.url') ? getThumbnail(tree.avatar.path) : null;

    const stagesAvatar = {
      'TreeStageEnum.seedling': germination,
      'TreeStageEnum.rooting': germination,
      'TreeStageEnum.germination': germination,
      'TreeStageEnum.vegetative': vegetative,
      'TreeStageEnum.flowering': flowering,
      'TreeStageEnum.drying': flowering,
      'TreeStageEnum.curing': flowering,
    };

    return treeAvatar || stagesAvatar[tree.stage];
  };

  return (
    <div
      role="button"
      tabIndex="0"
      className="card"
      style={{ backgroundImage: `url(${getTreeAvatar()})` }}
      onClick={onClick}
      onKeyPress={onClick}
    >
      <Hidden smUp>
        <Box display="flex" justifyContent="space-between">
          <div className="card-details-name">
            <p>{environment.name}</p>
            <h4>{tree.name}</h4>
          </div>
        </Box>
      </Hidden>
      <Box className="card-details">
        <Hidden xsDown>
          <Box display="flex" justifyContent="space-between">
            <div>
              <p>{environment.name}</p>
              <h4>{tree.name}</h4>
            </div>
          </Box>
          <Grid container className="card-details-growlog-data">
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Icon
                  rounded
                  name="icon-strain"
                  size={24}
                  margin={0}
                  border="#FFFFFF"
                  color="#FFFFFF"
                  backgroundColor="rgba(130, 130, 130, 0.1)"
                />
                <p>{strain.name}</p>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Icon
                  name={`icon-${environmentType[environment.type].toLowerCase()}`}
                  rounded
                  size={24}
                  margin={0}
                  border="#FFFFFF"
                  color="#FFFFFF"
                  backgroundColor="rgba(130, 130, 130, 0.1)"
                />
                <p>{environmentType[environment.type]}</p>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Icon
                  rounded
                  name={`icon-${stagesTypes[tree.stage]}`}
                  size={25}
                  margin={0}
                  backgroundColor={stageColors[stagesTypes[tree.stage]].background}
                  border={stageColors[stagesTypes[tree.stage]].border}
                  color={stageColors[stagesTypes[tree.stage]].border}
                />
                <p>
                  {`${moment().diff(tree.germinationDate, 'week')} Weeks old`}
                </p>
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Hidden xsDown>
            <Box display="flex">
              <div className="card-details-social-icons">
                {views}
                <Views name="icon-eyes" className="margin-left-10" />
              </div>
            </Box>
            {
             lastUpdate && (
               <span className="card-label">{moment(lastUpdate).fromNow()}</span>
             )
            }
          </Hidden>
          <Hidden smUp>
            <Box display="flex" flexDirection="column" justifyContent="space-around">
              <span>{strain.name}</span>
              {`${Math.ceil(age / 7)} Weeks`}
            </Box>
            <Box display="flex" flexDirection="column">
              <div className="card-details-social-icons">
                {views}
                <Views name="icon-eyes" className="margin-left-10" />
              </div>
            </Box>
          </Hidden>
        </Box>
      </Box>
    </div>
  );
};


GrowlogCard.propTypes = {
  tree: shape({}),
  environment: shape({}),
  strain: shape({}),
  views: number,
  age: number,
  lastUpdate: string,
  onClick: func,
};

GrowlogCard.defaultProps = {
  tree: {},
  environment: {},
  strain: {
    name: 'Unknown seed',
  },
  views: 0,
  age: null,
  lastUpdate: null,
  onClick: () => {},
};

export default GrowlogCard;
