const stageColors = {
  flowering: { border: '#BB6BD9', background: 'rgba(187, 107, 217, 0.4)' },
  vegetative: { border: '#4CAF50', background: 'rgba(33, 150, 83, 0.4)' },
  germination: { border: '#E4B65B', background: 'rgba(228, 182, 91, 0.4)' },
  seedling: { border: '#56CCF2', background: 'rgba(86, 204, 242, 0.4)' },
  rooting: { border: '#56CCF2', background: 'rgba(86, 204, 242, 0.4)' },
  drying: { border: '#F57F17', background: 'rgba(245, 127, 23, 0.4)' },
  curing: { border: '#FF5722', background: 'rgba(255, 87, 34, 0.4)' },
  dead: { border: '#4F4F4F', background: 'rgba(79,79,79,0.4)' },
};

const stagesTypes = {
  'TreeStageEnum.germination': 'germination',
  'TreeStageEnum.seedling': 'seedling',
  'TreeStageEnum.rooting': 'rooting',
  'TreeStageEnum.vegetative': 'vegetative',
  'TreeStageEnum.flowering': 'flowering',
  'TreeStageEnum.drying': 'drying',
  'TreeStageEnum.curing': 'curing',
  'TreeStageEnum.dead': 'dead',
};

const sortedStages = (isClone = true) => {
  const stages = Object.values(stagesTypes);
  const stageToRemove = isClone ? 'seedling' : 'rooting';
  return stages.filter((stage) => stage !== stageToRemove);
};

const lightTypes = {
  'LightingTypeEnum.led': 'LED',
  'LightingTypeEnum.cfl': 'CFL',
  'LightingTypeEnum.hps': 'HPS',
  'LightingTypeEnum.mh': 'MH',
  'LightingTypeEnum.lec': 'LEC',
  'LightingTypeEnum.t5': 'T5',
};

const mediumTypes = {
  'MediumTypeEnum.soil': 'Soil',
  'MediumTypeEnum.coco': 'Coco',
  'MediumTypeEnum.hydro': 'Hydro',
  'MediumTypeEnum.other': 'Other',
};

const environmentType = {
  'EnvironmentTypeEnum.outdoor': 'Outdoor',
  'EnvironmentTypeEnum.indoor': 'Indoor',
};

const remindersTypes = {
  'ReminderTypeEnum.watering': {
    color: '#56CCF2',
    name: 'Watering',
    icon: 'icon-watering',
    extraData: { icon: 'icon-watering', name: 'amount' },
  },
  'ReminderTypeEnum.nutrients': {
    color:
    '#72d572',
    name: 'Nutrients',
    icon: 'icon-nutrient',
    extraData: { icon: 'icon-application_method', name: 'application_method' },
  },
  'ReminderTypeEnum.repelent': {
    color: '#ff5722',
    name: 'Repelent',
    icon: 'icon-repelent',
    extraData: { icon: 'icon-application_method', name: 'application_method' },
  },
  'ReminderTypeEnum.trim': {
    color: '#a5a5a5',
    name: 'Trim',
    icon: 'icon-trim',
    extraData: { icon: 'icon-trim', name: 'trim_type' },
  },
  'ReminderTypeEnum.training': {
    color: '#a5a5a5',
    name: 'Training',
    icon: 'icon-training',
    extraData: { icon: 'icon-trim', name: 'training_type' },
  },
  'ReminderTypeEnum.transplant': { color: '#b1674c', name: 'Transplant', icon: 'icon-transplant' },
  'ReminderTypeEnum.change_environment': { color: '#a5a5a5', name: 'Change environment', icon: 'icon-other' },
  'ReminderTypeEnum.flush': { color: '#a5a5a5', name: 'Flush', icon: 'icon-flush' },
  'ReminderTypeEnum.harvest': { color: '#cb5dd2', name: 'Harvest', icon: 'icon-harvest' },
  'ReminderTypeEnum.declare_death': { color: '#4F4F4F', name: 'Declare death', icon: 'icon-death' },
  'ReminderTypeEnum.other': { color: '#a5a5a5', name: 'Other', icon: 'icon-action' },
};

const remindersExtraData = {
  'ApplicationMethodEnum.liquid': 'Liquid',
  'ApplicationMethodEnum.spray': 'Spray',
  'ApplicationMethodEnum.solid': 'Solid',
  'TrimTypeEnum.topping': 'Topping',
  'TrimTypeEnum.fim': 'FIM',
  'TrimTypeEnum.defoliation': 'Defoliation',
  'TrimTypeEnum.lollipopping': 'Lollipopping',
  'TrimTypeEnum.lower_branches': 'Lower branches',
  'TrainingTypeEnum.fim': 'FIM',
  'TrainingTypeEnum.lst': 'LST (Low stress training)',
  'TrainingTypeEnum.scrog': 'SCROG',
  'TrainingTypeEnum.super_cropping': 'Super cropping',
  'TrainingTypeEnum.topping': 'Topping',
  'VolumeUnitEnum.cubicMilliMetre': 'mm³',
  'VolumeUnitEnum.cubicCentiMetre': 'cm³',
  'VolumeUnitEnum.cubicMetre': 'm³',
  'VolumeUnitEnum.cubicInch': 'in³',
  'VolumeUnitEnum.cubicFoot': 'ft³',
  'VolumeUnitEnum.gallon_imperial': 'gal',
  'VolumeUnitEnum.milliLitre': 'ml',
  'VolumeUnitEnum.litre': 'l',
  'VolumeUnitEnum.cup_metric': 'cups',
  'VolumeUnitEnum.fluidOunce_imperial': 'fl. oz',
  'VolumeUnitEnum.pint_imperial': 'pint',
  'VolumeUnitEnum.tablespoon_metric': 'tbsp',
  'VolumeUnitEnum.teaspoon_metric': 'tsp',
};

const reminderFrequency = {
  'FrequencyEnum.noRepeat': '',
  'FrequencyEnum.daily': 'days',
  'FrequencyEnum.weekly': 'week',
  'FrequencyEnum.monthly': 'month',
};

const reminderLimitType = {
  'LimitTypeEnum.date': 'LimitTypeEnum.date',
  'LimitTypeEnum.forever': 'LimitTypeEnum.forever',
  'LimitTypeEnum.times': 'LimitTypeEnum.times',
  'LimitTypeEnum.stage': 'LimitTypeEnum.stage',
};

const reminderWeeklyDays = {
  'WeeklyDaysEnum.monday': 'monday',
  'WeeklyDaysEnum.tuesday': 'tuesday',
  'WeeklyDaysEnum.wednesday': 'wednesday',
  'WeeklyDaysEnum.thursday': 'thursday',
  'WeeklyDaysEnum.friday': 'friday',
  'WeeklyDaysEnum.saturday': 'saturday',
  'WeeklyDaysEnum.sunday': 'sunday',
};

const treeLogTypes = {
  height: { label: 'Height', icon: 'icon-height', suffix: 'cm' },
  ppm: { label: 'PPM', icon: 'icon-ppm', suffix: '' },
  ph: { label: 'PH', icon: 'icon-ph', suffix: '' },
  note: { label: 'Note', icon: 'icon-log', suffix: '' },
  waterTemp: { label: 'Water temperature', icon: 'icon-water_temperature', suffix: '°C' },
  ec: { label: 'EC', icon: 'icon-ec', suffix: '' },
};

const envLogTypes = {
  humidity: { label: 'Humidity', icon: 'icon-humidity', suffix: '%' },
  envTemperature: { label: 'Environment temperature', icon: 'icon-environment_temp', suffix: '°C' },
  roomTemperature: { label: 'Room temperature', icon: 'icon-outside-temp', suffix: '°C' },
  note: { label: 'Note', icon: 'icon-log', suffix: '' },
  lightDistance: { label: 'Light distance', icon: 'icon-light_hanging_distance' },
  co2: { label: 'CO2', icon: 'icon-co2', suffix: '%' },
  precipitations: { label: 'Precipitations', icon: 'icon-precipitations' },
};

const temperatureUnits = {
  'TemperatureEnum.fahrenheit': '°F',
  'TemperatureEnum.celcius': '°C',
};

const lengthEnum = {
  'LengthEnum.centimeter': 'cm',
  'LengthEnum.inch': 'in',
};

const filterTypesForApi = {
  EnvironmentTypeEnum: 'environment.type',
  TreeStageEnum: 'tree.stage',
  MediumTypeEnum: 'tree.mediumType',
  LightingTypeEnum: 'environment.lights.type',
};

export {
  stageColors,
  sortedStages,
  lightTypes,
  mediumTypes,
  environmentType,
  remindersTypes,
  treeLogTypes,
  envLogTypes,
  stagesTypes,
  remindersExtraData,
  temperatureUnits,
  lengthEnum,
  filterTypesForApi,
  reminderFrequency,
  reminderLimitType,
  reminderWeeklyDays,
};
