import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Universal from './universal';
import Growlog from './growlog';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  Universal,
  Growlog,
});

export default createRootReducer;
