import React from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { shape } from 'prop-types';
import { ListItem, Icon } from '../../../../components';
import {
  stageColors,
  lightTypes,
  mediumTypes,
  environmentType,
  lengthEnum,
  sortedStages,
} from '../../../../utils/constants';

const getGradients = (tree, stage) => {
  const stages = sortedStages(tree.isClone);
  const nextStage = stages[stages.indexOf(stage) + 1];
  console.log(nextStage);

  return `linear-gradient(${stageColors[stage].border},${stageColors[nextStage].border})`;
};

const Environment = ({
  tree, environment, strain, lengthUnit,
}) => (
  <>
    <p className="list-title">STRAIN</p>
    <ListItem
      iconName="icon-strain"
      label={strain && strain.breederName ? strain.breederName : 'Unknown breeder'}
      value={strain ? strain.name : 'Unknown seed'}
    />
    <p className="list-title">TREE STAGES</p>
    <Box margin="10px 10px 50px 10px">
      {
        sortedStages(tree.isClone).map((key, index) => tree.stages[key] && (
          <>
            <div className="clearfix stage-content">
              <div className="float-left stage-icon">
                <Icon
                  rounded
                  name={`icon-${key}`}
                  size={25}
                  margin={0}
                  backgroundColor={stageColors[key].background}
                  border={stageColors[key].border}
                  color={stageColors[key].border}
                />
                {
                  index + 1 !== Object.keys(tree.stages).length
                    ? (
                      <div
                        className="timeline-line "
                        style={{ background: getGradients(tree, key) }}
                      />
                    )
                    : null
                }
              </div>
              <div className="float-left stage-details">
                <p className="stage-name">{key}</p>
                <p>{moment(tree.stages[key]).format('MMM DD, YYYY')}</p>
              </div>
            </div>
          </>
        ))
      }
    </Box>
    {
      tree.mediumDesc ? (
        <>
          <p className="list-title">MEDIUM</p>
          <ListItem
            iconName="icon-medium"
            label={mediumTypes[tree.mediumType]}
            value={tree.mediumDesc}
          />
        </>
      ) : null
    }
    {
      environment ? (
        <>
          <p className="list-title">ENVIRONMENT</p>
          <ListItem
            iconName="icon-environment"
            label="Name"
            value={environment.name}
          />
          <ListItem
            iconName={`icon-${(environmentType[environment.type] || '').toLowerCase()}`}
            label="Type"
            value={environmentType[environment.type]}
          />
          <ListItem
            iconName="icon-time"
            label="Exposure Time"
            value={`${environment.exposureTime} Hours`}
          />
          {
            environmentType[environment.type] === 'Indoor'
            && environment.indoorWidth
              ? (
                <>
                  <ListItem
                    iconName="icon-height"
                    label="Environment Size"
                    value={`${environment.indoorWidth} ${lengthEnum[lengthUnit] || 'cm'} x ${environment.indoorHeight} ${lengthEnum[lengthUnit] || 'cm'} x ${environment.indoorLength} ${lengthEnum[lengthUnit] || 'cm'}`}
                  />

                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    marginBottom="20px"
                  >
                    <Icon name="icon-indoor" size={30} margin="0 10px 0 0" />
                    <div>
                      <p className="list-label">Lights</p>
                      {
                        environment.lights.map(({ type, wattage }) => (
                          <p>{`${lightTypes[type]} - ${wattage} W`}</p>
                        ))
                      }
                    </div>
                  </Box>
                </>
              )
              : null
          }
        </>
      ) : null
    }
  </>
);

Environment.propTypes = {
  tree: shape({}).isRequired,
  strain: shape({}).isRequired,
  environment: shape({}).isRequired,
  lengthUnit: shape({}),
};

Environment.defaultProps = {
  lengthUnit: 'LengthEnum.centimeter',
};

export default Environment;
