import { call, put } from 'redux-saga/effects';
import { getGrowlogData, getGrowlogsAPI } from '../services/growlog';
import { setGrowlog } from '../actions/growlog';
import { setGrowlogs } from '../actions/exploreGrowlogs';

export function* getGrowlog({ payload }) {
  try {
    yield put({ type: 'FETCH_INIT' });

    const { data } = yield call(getGrowlogData, payload);
    yield document.title = `${data.tree.name} Growlog`;
    yield put(setGrowlog(data));

    yield put({ type: 'FETCH_REQUEST_END' });
  } catch (e) {
    // awindow.location.replace('/404');
  }
}

export function* getGrowlogs({ payload }) {
  try {
    const { data } = yield call(getGrowlogsAPI, payload);

    yield put(setGrowlogs(data));
  } catch (e) {
    console.warn(e);
  }
}
