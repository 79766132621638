import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
// import googlePlay from '../../assets/img/google-play-badge.png';
// import appStore from '../../assets/img/app-store.png';
// import instagram from '../../assets/img/instagram.svg';
import options from './config';

const Footer = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const checkWindowSize = () => setIsMobile(window.innerWidth < 600);

  useEffect(() => {
    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();
  }, []);


  const handleMenu = (label) => setOpenMenu(openMenu !== label ? label : null);

  return (
    <footer className="footer container">
      <Grid container direction="row" justify="space-between">
        <Grid item xs="12" sm md="12">
          <Grid container direction="row">
            {
              options.map(({ label, menuOptions }) => (
                <Grid item xs="12" sm md="3" lg="3">
                  <div className="footer-section">
                    <Grid
                      container
                      tabIndex="0"
                      role="button"
                      onClick={() => handleMenu(label)}
                      onKeyDown={() => handleMenu(label)}
                      justify="space-between"
                      direction="row"
                    >
                      <h5>{label}</h5>
                      {isMobile && (openMenu === label ? <ExpandLess /> : <ExpandMore />)}
                    </Grid>
                    <Collapse in={isMobile ? openMenu === label : true}>
                      <Grid container direction="column">
                        {
                          menuOptions.map(({ optionlabel, to }) => (
                            <Link to={to}>{optionlabel}</Link>
                          ))
                        }
                      </Grid>
                    </Collapse>
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
        {/* <Grid item>
          <Grid container direction="column" className="footer-section">
            <h5>Get Jane</h5>
            <img className="footer-img" src={googlePlay} alt="grow with jane google play" />
            <img className="footer-img" src={appStore} alt="grow with jane app store" />
            <img className="footer-img-instagram" src={instagram} alt="grow with jane instagram" />
          </Grid>
        </Grid> */}
        <Grid container direction="row" justify="space-between" alignItems="flex-end">
          <Grid item xs="12" sm="12" md="12">
            <Grid container direction="row" alignItems="flex-end">
              <p>“Grow With Jane” is a company of Los Redondos INC. All rights reserved</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
