import React from 'react';
import { string, bool, number } from 'prop-types';

const Icon = ({
  name,
  rounded,
  color,
  size,
  margin,
  backgroundColor,
  border,
}) => {
  const isRounded = (param) => (rounded ? param : null);

  return (
    <div
      className={isRounded('icon-rounded')}
      style={{
        backgroundColor,
        margin,
        border: `1px solid ${border}`,
        width: isRounded(size * 2),
        height: isRounded(size * 2),
      }}
    >
      <i className={name} style={{ color, fontSize: size }} />
    </div>
  );
};

Icon.propTypes = {
  name: string.isRequired,
  rounded: bool,
  color: string,
  size: number,
  margin: number,
  backgroundColor: string,
  border: string,
};

Icon.defaultProps = {
  rounded: false,
  color: null,
  margin: null,
  backgroundColor: null,
  border: null,
  size: 16,
};

export default Icon;
