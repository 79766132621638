import { createSelector } from 'reselect';

const getGrowlogsList = createSelector(
  (state) => state.Growlog.growlogsList,
  (growlogs) => ({
    ...growlogs,
    rows: growlogs.rows,
  }),
);

export default getGrowlogsList;
