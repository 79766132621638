/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import moment from 'moment';
import { Modal, Grid, Hidden } from '@material-ui/core';
import Carousel from 'react-images';
import CloseIcon from '@material-ui/icons/Close';
import { shape, func, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { lightTypes, lengthEnum } from '../../../../utils/constants';

const ModalPhotos = ({
  hideModal,
  modalData,
  treeCreateDate,
  tree,
  environment,
  lengthUnit,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [currentIndex, setCurrentIndex] = useState(modalData.currentIndex);

  const handleViewChange = (index) => {
    const photoSelected = modalData.images.find(({ id }, imageIndex) => imageIndex === index);

    setCurrentIndex(index);
    history.push(`${pathname.substring(0, pathname.indexOf('/p/'))}/p/${btoa(photoSelected.id)}`);
  };

  const calculateWeekRange = () => {
    const date = moment(modalData.images[currentIndex].date).add(7, 'days').subtract(1, 'weeks');

    return `${date.startOf('week').format('MMM Do YYYY')} - ${date.endOf('week').format('MMM Do YYYY')}`;
  };

  return (
    <Modal
      className="modal-photos"
      open={modalData}
      onClose={hideModal}
    >
      <>
        <CloseIcon
          fontSize="large"
          className="modal-photos__close"
          onClick={hideModal}
        />
        <div className="modal-photos__container">
          <div className="modal-photos__container__carousel">
            <Carousel
              views={modalData.images}
              components={{ Footer: null }}
              trackProps={{
                onViewChange: handleViewChange,
              }}
              currentIndex={currentIndex}
            />
          </div>
          <div className="modal-photos__info">
            <div>
              <p className="modal-photos__info__date">
                {calculateWeekRange()}
              </p>
              <h5>
                {
                  `Week ${
                    moment(modalData.images[currentIndex].date)
                      .diff(treeCreateDate, 'week')}`
                }
              </h5>
              <h3 className="modal-photos__info__title">
                <i className="icon-flowering" />
                {tree.name}
              </h3>
              {
                modalData.images[currentIndex].note && (
                  <>
                    <h6>Note</h6>
                    <p>{modalData.images[currentIndex].note}</p>
                  </>
                )
              }
            </div>
            <Hidden mdDown>
              <Grid container>
                <Grid item xs="6">
                  <p className="modal-photos__info__label">Seed Strain</p>
                </Grid>
                <Grid item xs="6">
                  {tree.strain ? tree.strain.name : 'Unknown strain'}
                </Grid>
                {
                  tree.mediumDesc && (
                    <>
                      <Grid item xs="6">
                        <p className="modal-photos__info__label">Medium</p>
                      </Grid>
                      <Grid item xs="6">
                        {tree.mediumDesc}
                      </Grid>
                    </>
                  )
                }
                {
                  environment.indoorWidth ? (
                    <>
                      <Grid item xs="6">
                        <p className="modal-photos__info__label">Environment size</p>
                      </Grid>
                      <Grid item xs="6">
                        {`${environment.indoorWidth} ${lengthEnum[lengthUnit] || 'cm'} x ${environment.indoorHeight} ${lengthEnum[lengthUnit] || 'cm'} x ${environment.indoorLength} ${lengthEnum[lengthUnit] || 'cm'}`}
                      </Grid>
                    </>
                  ) : null
                }
                <Grid item xs="6">
                  <p className="modal-photos__info__label">Exposure Time</p>
                </Grid>
                <Grid item xs="6">
                  {`${environment.exposureTime} Hours`}
                </Grid>
                <Grid item xs="6">
                  <p className="modal-photos__info__label">Lights</p>
                </Grid>
                <Grid item xs="6">
                  {
                    environment.lights.map(({ type, wattage }) => (
                      <p className="modal-photos__info__no-margin">{`${lightTypes[type]} - ${wattage} W`}</p>
                    ))
                  }
                </Grid>
              </Grid>
            </Hidden>
          </div>
        </div>
      </>
    </Modal>
  );
};

ModalPhotos.propTypes = {
  hideModal: func.isRequired,
  modalData: shape({}).isRequired,
  treeCreateDate: string.isRequired,
  tree: shape({}).isRequired,
  environment: shape({}).isRequired,
  lengthUnit: string,
};

ModalPhotos.defaultProps = {
  lengthUnit: 'LengthEnum.centimeter',
};

export default ModalPhotos;
