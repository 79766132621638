import React from 'react';
import { element } from 'prop-types';

const PlainLayout = ({ children }) => (
  <>
    <main>
      {children}
    </main>
  </>
);

PlainLayout.propTypes = {
  children: element.isRequired,
};

export default PlainLayout;
