import React from 'react';
import { useSpring, animated } from 'react-spring';
import { bool, string } from 'prop-types';
import { Button as MaterialButton } from '@material-ui/core';
import LoaderSmall from '../LoaderSmall';

const ButtonSpinner = ({ isLoading, name, inheritSize }) => {
  /* showLoader is used to stay in the "isLoading state" a bit longer to avoid loading flashes
   if the loading state is too short. */
  const [showLoader, setShowLoader] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef(null);
  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 });
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 });

  React.useEffect(() => {
    if (isLoading) {
      setShowLoader(true);
    }

    // Show loader a bits longer to avoid loading flash
    if (!isLoading && showLoader) {
      setTimeout(() => {
        setShowLoader(false);
      }, 400);

    //   return () => {
    //     clearTimeout(timeout);
    //   };
    }
  }, [isLoading, showLoader]);

  /* Capture the dimensions of the button before the loading happens
  so it doesn’t change size.
  These hooks can be put in a seprate file. */
  React.useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [name]);

  return (
    <MaterialButton
      type="submit"
      className="button"
      ref={ref}
      style={
        !inheritSize && showLoader ? { width: `${width}px`, height: `${height}px` } : {}
      }
    >
      {showLoader ? (
        <animated.div style={fadeOutProps}>
          <LoaderSmall />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>{name}</animated.div>
      )}
    </MaterialButton>
  );
};

ButtonSpinner.propTypes = {
  name: string.isRequired,
  isLoading: bool,
  inheritSize: bool,
};

ButtonSpinner.defaultProps = {
  isLoading: false,
  inheritSize: false,
};

export default ButtonSpinner;
