import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ClickAwayListener } from '@material-ui/core';
import {
  string, element, arrayOf, bool, func,
} from 'prop-types';

const Dropdown = ({
  name,
  options,
  children,
  handleChange,
  border,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  const clickOption = (value, onClick) => {
    if (onClick) onClick();

    if (handleChange) handleChange(value);

    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="dropdown">
        <div
          tabIndex="0"
          role="button"
          className={`dropdown__button ${!border && 'dropdown__button__no-border'}`}
          onClick={handleOpen}
          onKeyDown={handleOpen}
        >
          {name}
          {
            !open
              ? <ArrowDropDownIcon className="dropdown__button__icon" />
              : <ArrowDropUpIcon className="dropdown__button__icon" />
          }
        </div>
        {
          open && (
            <div className="dropdown__options_group">
              {
                options.map(({ label, value, onClick }) => (
                  <div
                    tabIndex="0"
                    role="button"
                    className="dropdown__options_group__item"
                    onClick={() => clickOption(value, onClick)}
                    onKeyDown={() => clickOption(value, onClick)}
                  >
                    {label}
                  </div>
                ))
              }
              {children}
            </div>
          )
        }
      </div>
    </ClickAwayListener>
  );
};

Dropdown.propTypes = {
  name: string.isRequired,
  options: arrayOf({}),
  children: element.isRequired,
  border: bool,
  handleChange: func,
};

Dropdown.defaultProps = {
  options: [],
  border: true,
  handleChange: () => {},
};

export default Dropdown;
