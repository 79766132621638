import React, { lazy } from 'react';
import { Grid } from '@material-ui/core';
import { shape, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import { sendAnalytics } from '../../../../utils/analytics';

const GrowlogCard = lazy(() => import('../../../../components/GrowlogCard'));

const GrowlogsList = ({ growlogs, isFetching }) => (
  <Grid container spacing={1}>
    { !growlogs.rows.length && <h1>Growlogs not found</h1> }
    {
      growlogs.rows.map(({
        id, views, slug, tree, environment, strain, age, lastUpdate,
      }) => (
        <Grid item xs={6} md={3} key={id}>
          <Link
            to={`/growlog/${slug}/`}
            onClick={() => sendAnalytics({
              category: 'explore-growlogs',
              action: 'open-growlog',
              treeName: tree.name,
              strain: strain?.name,
              environmentType: environment.type,
              stage: tree.stage,
              views,
            })}
          >
            <GrowlogCard
              tree={tree}
              environment={environment}
              strain={strain}
              age={age}
              lastUpdate={lastUpdate}
              views={views}
            />
          </Link>
        </Grid>
      ))
    }
    {
      isFetching && growlogs.totalPages !== growlogs.page && <Loader />
    }
  </Grid>
);

GrowlogsList.propTypes = {
  growlogs: shape({}).isRequired,
  isFetching: bool,
};

GrowlogsList.defaultProps = {
  isFetching: false,
};

export default React.memo(GrowlogsList);
