import {
  GET_GROWLOG_BY_ID,
  SET_GROWLOG_BY_ID,
  CLEAR_GROWLOG,
} from '../actionTypes';

export const getGrowlog = (payload) => ({
  type: GET_GROWLOG_BY_ID,
  payload,
});

export const setGrowlog = (payload) => ({
  type: SET_GROWLOG_BY_ID,
  payload,
});

export const clearGrowlog = () => ({
  type: CLEAR_GROWLOG,
});
