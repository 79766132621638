import { takeLatest, all } from 'redux-saga/effects';

import { GET_GROWLOG_BY_ID, GET_GROWLOGS } from '../actionTypes';

import { getGrowlog, getGrowlogs } from './growlog';

function* mySaga() {
  try {
    yield all([
      yield takeLatest(GET_GROWLOG_BY_ID, getGrowlog),
      yield takeLatest(GET_GROWLOGS, getGrowlogs),
    ]);
  } catch (err) {
    console.error('mySaga Error: ', err);
  }
}


export default mySaga;
