import React from 'react';
import { element } from 'prop-types';
import Header from '../Header';
import Footer from '../Footer/index';

const Layout = ({ children }) => (
  <>
    <Header />
    <main>
      {children}
    </main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: element.isRequired,
};

export default Layout;
