import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout, PlainLayout } from './components';
import Growlog from './containers/Growlog';
import ExploreGrowlogs from './containers/ExploreGrowlogs';
import Profile from './containers/Profile';
import Login from './containers/Login';
import Register from './containers/Register';
import Onboarding from './containers/Onboarding';

const Router = () => (
  <>
    <Switch>
      <Route path="/onboarding">
        <PlainLayout>
          <Onboarding />
        </PlainLayout>
      </Route>
      <Layout>
        <Route exact path="/growlog/:id" component={Growlog} />
        <Route exact path="/growlog/:id/p/:image" component={Growlog} />
        <Route exact path="/growlog-hide-layout/:id" component={Growlog} />
        <Route exact path="/explore-growlogs" component={ExploreGrowlogs} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
      </Layout>
    </Switch>
    {/* </Route> */}
  </>
);

export default Router;
