import { useState, useEffect } from 'react';

const useIsMobile = (screenWidth) => {
  const [isMobile, setIsMobile] = useState(false);

  const checkWindowSize = () => setIsMobile(window.innerWidth < screenWidth);

  useEffect(() => {
    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();
  }, []);

  return isMobile;
};

export default useIsMobile;
