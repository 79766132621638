
const initialState = {
  request: {
    error: false,
    process: false,
  },
};

const Universal = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        request: {
          error: false,
          process: true,
        },
      };

    case 'FETCH_REQUEST_END':
      return { ...state, request: { ...state.request, process: false } };
    default:
      return state;
  }
};

export default Universal;
