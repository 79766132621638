export default [
  {
    name: 'All environments',
    selectedLabel: 'Environments',
    type: 'EnvironmentTypeEnum',
    options: [
      {
        label: 'Outdoor',
        value: 'EnvironmentTypeEnum.outdoor',
      },
      {
        label: 'Indoor',
        value: 'EnvironmentTypeEnum.indoor',
      },
    ],
  },
  {
    name: 'All stages',
    selectedLabel: 'Stages',
    type: 'TreeStageEnum',
    options: [
      {
        label: 'Germination',
        value: 'TreeStageEnum.germination',
      },
      {
        label: 'Seedling',
        value: 'TreeStageEnum.seedling',
      },
      {
        label: 'Rooting',
        value: 'TreeStageEnum.rooting',
      },
      {
        label: 'Vegetative',
        value: 'TreeStageEnum.vegetative',
      },
      {
        label: 'Flowering',
        value: 'TreeStageEnum.flowering',
      },
      {
        label: 'Drying',
        value: 'TreeStageEnum.drying',
      },
      {
        label: 'Curing',
        value: 'TreeStageEnum.curing',
      },
    ],
  },
  {
    name: 'All Mediums',
    selectedLabel: 'Mediums',
    type: 'MediumTypeEnum',
    options: [
      {
        label: 'Soil',
        value: 'MediumTypeEnum.soil',
      },
      {
        label: 'Coco',
        value: 'MediumTypeEnum.coco',
      },
      {
        label: 'Hydro',
        value: 'MediumTypeEnum.hydro',
      },
      {
        label: 'Other',
        value: 'MediumTypeEnum.other',
      },
    ],
  },
  {
    name: 'All Lights',
    selectedLabel: 'Lights',
    type: 'LightingTypeEnum',
    options: [
      {
        label: 'LED',
        value: 'LightingTypeEnum.led',
      },
      {
        label: 'CFL',
        value: 'LightingTypeEnum.cfl',
      },
      {
        label: 'HPS',
        value: 'LightingTypeEnum.hps',
      },
      {
        label: 'MH',
        value: 'LightingTypeEnum.mh',
      },
      {
        label: 'LEC',
        value: 'LightingTypeEnum.lec',
      },
      {
        label: 'T5',
        value: 'LightingTypeEnum.t5',
      },
    ],
  },
];
