import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-74719637-1');
};

export const sendAnalytics = ({ category, action, ...eventFields }) => {
  ReactGA.event({
    category,
    action,
    ...eventFields,
  });
};

export const sendPageView = (pageCode) => {
  ReactGA.pageview(pageCode);
};
