/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardMedia,
  TextField,
  Button,
} from '@material-ui/core';
// import CameraIcon from '@material-ui/icons/AddAPhoto';
import janeVertical from '../../assets/img/logo-vertical.png';
import onboarding from '../../assets/img/fadeout.jpg';
import firebase from '../../utils/firebase';
import config from '../../config';
import { history } from '../../redux/store';
import ButtonSpinner from '../../components/ButtonSpinner';
import { initGA, sendPageView, sendAnalytics } from '../../utils/analytics';

const Onboarding = () => {
  const { handleSubmit, register, errors } = useForm();
  const [formState, setFormState] = useState({
    showRegister: false,
    avatar: null,
  });
  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const handleJoin = (e) => {
    e.persist();
    sendAnalytics({
      category: 'onboarding',
      action: 'join',
    });
    setFormState((prevFormState) => ({
      ...prevFormState,
      showRegister: !prevFormState.showRegister,
    }));
  };
  const searchParams = new URLSearchParams(window.location.search);
  const showEmail = searchParams.get('error') === 'noemail';

  useEffect(() => {
    initGA();
    sendPageView('onboarding');
  }, []);

  const onSubmit = (values) => {
    setIsFormLoading(true);
    firebase.onBoarding(values).then(() => {
      if (showEmail) {
        history.push('/login?error=newemail');
      } else {
        window.location.href = `${config.DISCOURSE_URL}/session/sso`;
      }
    });
  };

  // const readURL = (e) => {
  //   const input = e.target;
  //   if (input.files && input.files[0]) {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       setFormState((prevFormState) => ({
  //         ...prevFormState,
  //         avatar: event.target.result,
  //       }));
  //     };
  //     reader.readAsDataURL(input.files[0]);
  //   }
  // };

  return (
    <div className="onboarding">
      <Card className="onboarding__card">
        <div className="onboarding__card__sidepanel">
          <CardMedia className="onboarding__card__image" image={onboarding} />
          <span className="credits">
            <span role="img" aria-label="photo">📷</span>
            by community member @fadeout
          </span>
        </div>
        <div className="onboarding__card__content">
          <div id="create-profile" className={!formState.showRegister ? 'hidden' : ''}>
            <form className="onboarding__card__content__form" onSubmit={handleSubmit(onSubmit)}>
              <img className="onboarding__card__content__logo" src={janeVertical} alt="logo-vertical" />
              <h1>Create a profile</h1>
              <h5>Complete your profile to be a part of the community</h5>
              {/* <IconButton
                component="label"
                className="profile"
              >
                <CameraIcon
                  className={formState.avatar ? 'hidden' : ''}
                />
                <img
                  src={formState.avatar}
                  alt="avatar"
                  className={!formState.avatar ? 'hidden' : ''}
                />
                <input
                  name="avatar"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={readURL}
                  ref={register()}
                />
              </IconButton> */}
              <TextField
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : 'Users can @mention you'}
                name="name"
                fullWidth
                // onChange={handleChange}
                className="onboarding__card__content__form__input"
                label="Display name"
                variant="outlined"
                inputRef={register({
                  required: 'A display name is required',
                  pattern: {
                    value: /^[A-Za-z0-9_\-.]+$/,
                    message: 'A display name must only include numbers, letters, dashes, dots, and underscores.',
                  },
                })}
              />
              {
                showEmail && (
                  <TextField
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : 'Will always remain private'}
                    name="email"
                    fullWidth
                    // onChange={handleChange}
                    className="onboarding__card__content__form__input"
                    label="E-mail"
                    variant="outlined"
                    inputRef={register({
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'Invalid email address',
                      },
                    })}
                  />
                )
              }
              <ButtonSpinner
                isLoading={isFormLoading}
                inheritSize
                name="Create Profile"
              />
            </form>
          </div>
          <div id="community-intro" className={formState.showRegister ? 'hidden' : ''}>
            <img className="onboarding__card__content__logo" src={janeVertical} alt="logo-vertical" />
            <h1>Your home growing community</h1>
            <h4>Be a part of the community and meet new cannabis home growers</h4>
            <h5>Create a social profile, list your growlogs, ask questions, get the best answers and join the homegrowing movement, for free.</h5>
            <Button onClick={handleJoin}>Join the Community</Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Onboarding;
