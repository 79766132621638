import React, { useState } from 'react';
import {
  Grid,
  Hidden,
  ListItemText,
  ListItem,
} from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import logo from '../../assets/img/logo-dark.png';
import options from './config';

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => setOpenMenu(!openMenu);

  return (
    <header className="header">
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className="wrapper container"
      >
        <Hidden mdUp>
          <a href="/">
            <img src={logo} className="header-logo" alt="logo" />
          </a>
        </Hidden>
        <Hidden smDown>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <a href="/">
                <img src={logo} className="header-logo" alt="logo" />
              </a>
              {
                options.default.map(({ label, to, left }) => left && (
                  <a href={to}>{label}</a>
                ))
              }
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smDown>
          {
            options.default.map(({ label, to, right }) => right && (
              <a href={to}>{label}</a>
            ))
          }
        </Hidden>
        <Hidden mdUp>
          <Menu className="menu_icon" onClick={toggleMenu} />
        </Hidden>
      </Grid>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={`dropdown_menu ${openMenu ? 'open' : ''}`}
        >
          {
            options.default.map(({ label, to }) => (
              <a href={to}>
                <ListItem button>
                  <ListItemText>{label}</ListItemText>
                </ListItem>
              </a>
            ))
          }
        </Grid>
      </Hidden>
    </header>
  );
};

export default Header;
