import { connect } from 'react-redux';
import Growlog from './main';
import { getGrowlog, clearGrowlog } from '../../redux/actions/growlog';
import getAllPhotos from '../../redux/selectors/growlog';

const mapStateToProps = (state) => ({
  growlogData: state.Growlog.growlogData,
  loading: state.Universal.request.process,
  galleryPhotos: getAllPhotos(state),
});

const mapDispatchToProps = {
  getGrowlog,
  clearGrowlog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Growlog);
