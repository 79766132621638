import axiosInstance from '../axiosInstance';

export const getGrowlogData = (id) => axiosInstance.get(`public/growlogs/get?slug=${id}`);

export const getGrowlogsAPI = ({
  page, search, filters, sortBy,
}) => (
  // eslint-disable-next-line max-len
  axiosInstance.get(`public/growlogs/list?page=${page}&pageSize=35&query=${JSON.stringify({ ...filters, active: 1 })}${search ? `&q=${search}` : ''}${sortBy ? `&sort=${sortBy}` : '&sort=-score,-lastUpdate'}`)
);
