/* eslint-disable react/no-array-index-key */
import React from 'react';
import StackGrid from 'react-stack-grid';
import CloseIcon from '@material-ui/icons/Close';
import {
  arrayOf,
  shape,
  func,
  bool,
  string,
} from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import useIsMobile from '../../utils/hooks/useIsMobile';

const GalleryModal = ({
  treeName,
  photos,
  showGallery,
  setModalPhotosData,
  hideModal,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isMobile = useIsMobile(600);

  const openModalPhoto = (index, id) => {
    history.replace(`${pathname}${pathname.substr(pathname.length - 1) !== '/' ? '/' : ''}p/${btoa(id)}`);
    setModalPhotosData({ images: photos, currentIndex: index });
  };

  if (showGallery) {
    return (
      <div className="gallery-modal">
        <div className="gallery-modal__content">
          <div className="gallery-modal__header">
            <div>
              <strong>
                {`${treeName} `}
              </strong>
              Photo Gallery
            </div>
            <CloseIcon
              className="gallery-modal__header__close-icon"
              fontSize="large"
              onClick={hideModal}
            />
          </div>
          <div className="gallery-modal__content__grid">
            <StackGrid
              monitorImagesLoaded
              duration={0}
              columnWidth={isMobile ? 125 : 180}
            >
              {
                photos.map(({ path, id }, index) => (
                  <div
                    tabIndex="0"
                    role="button"
                    key={index}
                    onClick={() => openModalPhoto(index, id)}
                    onKeyPress={() => openModalPhoto(index, id)}
                  >
                    <img src={path} alt={`gallery-${path}`} />
                  </div>
                ))
              }
            </StackGrid>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

GalleryModal.propTypes = {
  treeName: string.isRequired,
  photos: arrayOf(shape({})).isRequired,
  setModalPhotosData: func.isRequired,
  hideModal: func.isRequired,
  showGallery: bool,
};

GalleryModal.defaultProps = {
  showGallery: false,
};

export default React.memo(GalleryModal);
