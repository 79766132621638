/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardMedia,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  Hidden,
  Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import googlePlay from '../../assets/img/google-play-badge.png';
import appStore from '../../assets/img/app-store.png';
import ButtonSpinner from '../../components/ButtonSpinner';
import janeVertical from '../../assets/img/logo-vertical-dark.png';
import googleDark from '../../assets/img/google-dark.svg';
import loginImg from '../../assets/login.png';
import firebase from '../../utils/firebase';
import config from '../../config';
import { initGA, sendPageView } from '../../utils/analytics';

const Login = () => {
  const { handleSubmit, register, errors } = useForm();
  // const [loadingSpeed] = React.useState(1);
  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const [formHasError, setFormHasError] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);

  useEffect(() => {
    initGA();
    sendPageView('login');
  }, []);

  const redirect = async () => {
    window.location.href = `${config.DISCOURSE_URL}/session/sso`;
  };

  const onSubmit = async (values) => {
    setIsFormLoading(true);
    setFormHasError(false);
    firebase.loginPassword(values.email, values.password)
      .then(redirect).catch((error) => {
        if (error.code === 'auth/invalid-email') {
          setErrorMsg('invalid email');
        } else if (error.code === 'auth/user-disabled') {
          setErrorMsg('user disabled');
        } else if (error.code === 'auth/user-not-found') {
          setErrorMsg('user not found');
        } else if (error.code === 'auth/wrong-password') {
          setErrorMsg('wrong pwd');
        } else {
          setErrorMsg('The was a problem during the sign in. Please try again later');
        }
        if (error.message) {
          setErrorMsg(error.message);
        }
        setIsFormLoading(false);
        setFormHasError(true);
      });
  };

  const googleConnect = (event) => {
    event.preventDefault();
    setIsFormLoading(true);
    firebase.loginGoogle().then(redirect).catch(() => { setIsFormLoading(false); });
  };

  const useQuery = () => new URLSearchParams(useLocation().search);

  return (
    <div className="login container">
      <Card className="login__card">
        <CardMedia className="login__card__image" image={loginImg} />
        <div className="login__card__content">
          <Hidden smDown>
            <img className="login__card__content__logo" src={janeVertical} alt="logo-vertical" />
          </Hidden>
          {useQuery().get('error') === 'newemail' ? (<Alert className="query" severity="info">Please login again using the email address provided</Alert>) : null}
          <h1>Sign In</h1>
          <Hidden smDown>
            <h5>Access your data with your registered username or e-mail address</h5>
          </Hidden>
          <form className="login__card__content__form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              error={!!errors.email}
              name="email"
              className="login__card__content__form__input"
              label="e-mail or username"
              variant="outlined"
              inputRef={register({
                required: 'Email or username is required',
              })}
            />
            <FormControl className="login__card__content__form__input" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="password"
                name="password"
                error={!!errors.password}
                // endAdornment={(
                //   <InputAdornment position="end">
                //     <IconButton
                //       aria-label="toggle password visibility"
                //       onClick={() => {
                //       }}
                //       onMouseDown={() => {
                //       }}
                //       edge="end"
                //     >
                //       {false ? <Visibility /> : <VisibilityOff />}
                //     </IconButton>
                //   </InputAdornment>
                // )}
                labelWidth={70}
                inputRef={register({
                  required: 'Required',
                })}
              />
            </FormControl>
            <ButtonSpinner
              isLoading={isFormLoading}
              name="SIGN IN"
            />
            {formHasError ? (<Alert severity="error">{errorMsg}</Alert>) : null}
          </form>
          <div className="login__card__content__google-sign-in">
            <p>or sign in with</p>
            <IconButton onClick={googleConnect}>
              <img
                className="login__card__content__google-sign-in__icon"
                src={googleDark}
                alt="google sign in"
              />
            </IconButton>
          </div>
        </div>
      </Card>
      <div className="login__create-account">
        <h1>Don't have a account?</h1>
        <h4>Download the Grow with Jane app to register and join the community!</h4>
        <Box display="flex" flexWrap="wrap">
          <a href="https://play.google.com/store/apps/details?id=com.unlogical.jane&referrer=utm_source%3Dwebsite%26utm_medium%3Dlogin%26utm_campaign%3Dget_app">
            <img className="download_app__container__download_image" src={googlePlay} alt="google play" />
          </a>
          <a href="https://apps.apple.com/app/apple-store/id1467850558?pt=119770304&ct=website-login-get_app&mt=8">
            <img className="download_app__container__download_image" src={appStore} alt="app store" />
          </a>
        </Box>
      </div>
    </div>
  );
};

export default Login;
