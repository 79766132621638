import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { func } from 'prop-types';
import useIsMobile from '../../../utils/hooks/useIsMobile';
import logo from '../../../assets/img/logo-dark.png';
import phone from '../../../assets/img/phone.png';
import phoneMobile from '../../../assets/img/phone-mobile.png';
import googlePlay from '../../../assets/img/google-play-badge.png';
import appStore from '../../../assets/img/app-store.png';

const DownloadApp = ({ hideDownloadApp }) => {
  const isMobile = useIsMobile(600);

  return (
    <div className="download_app">
      <div className="download_app__container">
        <Hidden smUp>
          <Box display="flex" justifyContent="space-between" marginX={1}>
            <img className="download_app__container__logo" src={logo} alt="grow with jane logo" />
            <button
              type="button"
              onClick={hideDownloadApp}
              className="download_app__container__close"
            >
              <CloseIcon />
              Close
            </button>
          </Box>
        </Hidden>
        <Box display="flex">
          <img src={isMobile ? phoneMobile : phone} alt="download app phone" className="download_app__container__phone" />
          <Box marginTop={3}>
            <Hidden xsDown>
              <Box display="flex" justifyContent="space-between" marginBottom={4}>
                <img className="download_app__container__logo" src={logo} alt="grow with jane logo" />
                <button
                  type="button"
                  onClick={hideDownloadApp}
                  className="download_app__container__close"
                >
                  <CloseIcon />
                  Close
                </button>
              </Box>
            </Hidden>
            <Box marginBottom={1} marginTop={2}>
              <p>Grow your own, share your growlogs with the community.</p>
            </Box>
            <p>
              <strong> Download Grow with Jane </strong>
              ,the best app for cannabis growers!
            </p>
            <Box display="flex" flexWrap="wrap">
              <a href="https://play.google.com/store/apps/details?id=com.unlogical.jane">
                <img className="download_app__container__download_image" src={googlePlay} alt="google play" />
              </a>
              <a href="https://apps.apple.com/ar/app/grow-with-jane-grow-journal/id1467850558">
                <img className="download_app__container__download_image" src={appStore} alt="app store" />
              </a>
            </Box>
          </Box>
        </Box>
      </div>
      <Hidden smUp>
        <a
          href="https://play.google.com/store/apps/details?id=com.unlogical.jane"
          className="download_app__button"
        >
          Install the app
        </a>
      </Hidden>
    </div>
  );
};

DownloadApp.propTypes = {
  hideDownloadApp: func.isRequired,
};

export default DownloadApp;
