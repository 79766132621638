export default [
  {
    label: 'Most viewed',
    value: '-views',
  },
  {
    label: 'Recent updates',
    value: '-lastUpdate',
  },
];
