import { createSelector } from 'reselect';

const getAllPhotos = createSelector(
  (state) => state.Growlog.growlogData,
  ({ events }) => {
    const evenstKeys = Object.keys(events);
    const eventsWithPhotos = evenstKeys.filter((key) => (!!events[key].photos));
    const photos = eventsWithPhotos
      .map((key) => events[key].photos.map(({ path, source, ...photo }) => ({
        ...photo,
        imageType: source,
        path,
        source: path,
      })))
      .reduce((acc, curr) => [...acc, ...curr], [])
      .filter(({ imageType }) => imageType === 'PhotoSourceEnum.url');

    return photos;
  },
);

export default getAllPhotos;
