import React from 'react';
import { string } from 'prop-types';
import Icon from '../Icon';

const Input = ({ placeholder }) => (
  <div className="input-container">
    <input
      placeholder={placeholder}
    />
    <div className="input-container-icon">
      <Icon name="icon-add" />
    </div>
  </div>
);

Input.propTypes = {
  placeholder: string,
};

Input.defaultProps = {
  placeholder: '',
};

export default Input;
