import React from 'react';
import { Box } from '@material-ui/core';
import { string, bool } from 'prop-types';
import Icon from '../Icon';

const ListItem = ({
  label,
  value,
  iconName,
  borderBottom,
}) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    marginBottom="20px"
  >
    <Icon name={iconName} size={30} margin="0 10px 0 0" />
    <div className={borderBottom ? 'list-border-bottom' : null}>
      <p className="list-label">{label}</p>
      <p>{value}</p>
    </div>
  </Box>
);

ListItem.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  iconName: string.isRequired,
  borderBottom: bool,
};

ListItem.defaultProps = {
  borderBottom: false,
};

export default ListItem;
