import {
  GET_GROWLOGS, SET_GROWLOGS, CLEAR_GROWLOGS_LIST,
} from '../actionTypes';

export const getGrowlogs = (payload) => ({
  type: GET_GROWLOGS,
  payload,
});

export const setGrowlogs = (payload) => ({
  type: SET_GROWLOGS,
  payload,
});

export const clearGrowlogList = () => ({
  type: CLEAR_GROWLOGS_LIST,
});
