import React from 'react';
import {
  Grid,
  Avatar,
  Box,
} from '@material-ui/core';
import { ListItem, GrowlogCard } from '../../components';
import data from '../../utils/data';

const Profile = () => (
  <>
    <div className="profile-header">
      <img className="profile-header-image" src={data.tree.avatar.path} alt="banner" />
      <Grid container alignItems="center" className="profile-header-title">
        <Avatar
          src={data.environment.avatar.path}
          className="profile-header-title-avatar"
        />
        <h1>Mary Jane</h1>
      </Grid>
    </div>
    <div>
      <Grid className="container" container>
        <Grid item xs={12} sm={12} md={4}>
          <p className="list-title">INFORMATION</p>
          <ListItem
            iconName="icon-dashboard"
            label="Growers Circle"
            value="Professional"
          />
          <ListItem
            iconName="icon-time"
            label="Time with jane"
            value="+ 2 years"
          />
          <ListItem
            borderBottom
            iconName="icon-log"
            label="Diaries"
            value="12 diaries"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <p className="list-title">STRAINS USED</p>
          <ListItem
            iconName="icon-strain"
            label="Seed Brand"
            value="Pinapple Express"
          />
          <ListItem
            iconName="icon-strain"
            label="Seed Brand"
            value="Pinapple Express"
          />
          <ListItem
            borderBottom
            iconName="icon-strain"
            label="Seed Brand"
            value="Pinapple Express"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <p className="list-title">STRAINS USED</p>
          <ListItem
            iconName="icon-strain"
            label="Seed Brand"
            value="Pinapple Express"
          />
          <ListItem
            iconName="icon-strain"
            label="Seed Brand"
            value="Pinapple Express"
          />
          <ListItem
            borderBottom
            iconName="icon-strain"
            label="Seed Brand"
            value="Pinapple Express"
          />
        </Grid>
        <Box width="100%" margin="30px 0">
          <h1>All growlogs</h1>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <GrowlogCard />
          </Grid>
        </Grid>
      </Grid>
    </div>
  </>
);

export default Profile;
