export default {
  default: [
    {
      label: 'Cannabis Blog',
      to: '/cannabis-blog/',
      left: true,
    },
    {
      label: 'Community',
      to: 'https://social.growithjane.com/',
      left: true,
    },
    {
      label: 'Explore growlogs',
      to: '/explore-growlogs/',
      left: true,
    },
    {
      label: 'Merch',
      to: '/merch/',
      left: true,
    },
    {
      label: 'Press',
      to: '/press/',
      left: true,
    },
    {
      label: 'About Us',
      to: '/about-us/',
      left: true,
    },
    // {
    //   label: 'Login / Register',
    //   to: '/login',
    //   right: true,
    // },
  ],
};
