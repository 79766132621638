import { connect } from 'react-redux';
import ExploreGrowlogs from './main';
import { getGrowlogs, clearGrowlogList } from '../../redux/actions/exploreGrowlogs';
import getGrowlogsList from '../../redux/selectors/growlogs';

const mapStateToProps = (state) => ({
  growlogs: getGrowlogsList(state),
});

const mapDispatchToProps = {
  getGrowlogs,
  clearGrowlogList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExploreGrowlogs);
