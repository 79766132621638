import moment from 'moment';

export const getTreeAge = (initialDate, diffDate) => {
  const ageNumber = moment(initialDate).diff(diffDate, 'day') + 1;
  const weeksNumber = Math.floor(ageNumber / 7);
  const remainingDays = ageNumber - weeksNumber * 7;

  let weeksNumberString = weeksNumber === 1 ? `${weeksNumber} week ` : `${weeksNumber} weeks`;
  weeksNumberString = weeksNumber > 0 ? weeksNumberString : '';

  let remainingDaysString = remainingDays === 1 ? `${remainingDays} day ` : `${remainingDays} days`;
  remainingDaysString = remainingDays > 0 ? remainingDaysString : '';

  return `${weeksNumberString} ${remainingDaysString}`;
};

// eslint-disable-next-line no-useless-escape
export const getThumbnail = (img) => img.replace('\%2F', '\%2Fthumb@480_');
