import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { string, bool } from 'prop-types';
import Loader from '../Loader';

const Button = ({ name, type, isLoading }) => (
  <MaterialButton className="button" type={type}>
    {name}
    {isLoading ? (<Loader />) : null}
  </MaterialButton>
);

Button.propTypes = {
  name: string.isRequired,
  type: string.isRequired,
  isLoading: bool,
};

Button.defaultProps = {
  isLoading: false,
};

export default Button;
