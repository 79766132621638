/* eslint-disable no-useless-escape */
export default {
  tree: {
    strain: {
      id: '-LnTGKO9UNSUT0QXtY2O',
      name: 'Platinum Gorilla Glue ',
    },
    environment: {
      name: 'Grow Box',
      id: '-LnTGKDnoDjtQ2-PPSpx',
    },
    stage: 'TreeStageEnum.flowering',
    weight: 5,
    avatar: {
      id: 'OZga5dwy4cGfzSIfcM94',
      path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568419188111.jpg?alt=media&token=205fd549-079e-44cb-b882-d7847eabd2da',
      source: 'PhotoSourceEnum.url',
    },
    mediumType: 'MediumTypeEnum.soil',
    harvestDate: '20190926',
    stageChanges: [
      {
        to: 'TreeStageEnum.germination',
        date: '20190623',
        from: 'null',
      },
      {
        to: 'TreeStageEnum.vegetative',
        date: '20190623',
        from: 'TreeStageEnum.germination',
      },
      {
        to: 'TreeStageEnum.flowering',
        date: '20190801',
        from: 'TreeStageEnum.vegetative',
      },
    ],
    mediumDesc: 'Miracle Grow!',
    environmentMoves: [

    ],
    state: 'TreeStateEnum.harvested',
    name: 'Platinum Gorilla Glue',
    stages: {
      germination: '20190623',
      vegetative: '20190623',
      flowering: '20190801',
    },
  },
  environment: {
    indoorHeight: 19,
    type: 'EnvironmentTypeEnum.indoor',
    avatar: {
      source: 'PhotoSourceEnum.url',
      id: '-LnTH3jQbP6w2Mhq3yqa',
      path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102336393.jpg?alt=media&token=34598e4c-d6cb-4a93-9eba-127631f88878',
    },
    indoorWidth: 20,
    exposureTime: 24,
    environment_changes: [
      {
        date: '20190829',
        environment: {
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '90',
            },
          ],
          name: 'Grow Box',
        },
        changes: [
          'EnvironmentChangeType.new_setup',
        ],
      },
      {
        original_environment: {
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
          lights: [
            {
              wattage: '90',
              type: 'LightingTypeEnum.led',
            },
          ],
        },
        changes: [

        ],
        date: '20190829',
        environment: {
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '90',
            },
          ],
          name: 'Grow Box',
        },
      },
      {
        date: '20190829',
        environment: {
          exposureTime: 12,
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '90',
            },
          ],
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
        },
        original_environment: {
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '90',
            },
          ],
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
        },
        changes: [

        ],
      },
      {
        original_environment: {
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '90',
            },
          ],
          name: 'Grow Box',
        },
        changes: [

        ],
        date: '20190829',
        environment: {
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '90',
            },
          ],
        },
      },
      {
        environment: {
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '25',
            },
          ],
        },
        original_environment: {
          lights: [
            {
              wattage: '90',
              type: 'LightingTypeEnum.led',
            },
          ],
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 12,
        },
        changes: [
          'EnvironmentChangeType.light_change',
        ],
        date: '20190906',
      },
      {
        date: '20191002',
        environment: {
          lights: [
            {
              type: 'LightingTypeEnum.led',
              wattage: '30',
            },
          ],
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
          exposureTime: 24,
        },
        original_environment: {
          exposureTime: 12,
          lights: [
            {
              wattage: '25',
              type: 'LightingTypeEnum.led',
            },
          ],
          name: 'Grow Box',
          type: 'EnvironmentTypeEnum.indoor',
        },
        changes: [
          'EnvironmentChangeType.exposition_change',
          'EnvironmentChangeType.light_change',
        ],
      },
    ],
    indoorLength: 33,
    lights: [
      {
        type: 'LightingTypeEnum.led',
        wattage: '30',
      },
    ],
    name: 'Grow Box',
  },
  strain: {
    description: 'Seeds from dispensary',
    seedFinderId: '4',
    strainClass: 'seeds.class.hybrid',
    strainSource: 'StrainSourceEnum.user',
    name: 'Platinum Gorilla Glue ',
    type: 'seeds.type.regular',
    breederId: null,
    breederName: null,
    id: '-LnTGKO9UNSUT0QXtY2O',
  },
  events: {
    20190623: {
      stageChange: [
        {
          _type: 'stageChange',
          active: true,
          date: '20190623',
          stage: 'vegetative',
        },
      ],
    },
    20190801: {
      stageChange: [
        {
          _type: 'stageChange',
          active: true,
          date: '20190801',
          stage: 'flowering',
        },
      ],
      photos: [
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190801',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097402704.jpg?alt=media&token=ad8f795d-70f4-45aa-afd1-d65ff8fc37c5',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564697325474.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCdib7VjbLNtuo0',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          active: true,
          id: '-LnTGKCrNAyHLli8NCbz',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190801',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102228407.jpg?alt=media&token=16139a11-4570-4197-a863-2a63da641b2f',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564697342578.png',
          source: 'PhotoSourceEnum.url',
        },
      ],
    },
    20190806: {
      photos: [
        {
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190806',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097194221.jpg?alt=media&token=9336b932-35a2-461e-94b3-e26fe4284cb9',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565122893590.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCZ3u8imfDfcSEO',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
        },
        {
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565122850969.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKC_ai2couQhQB02',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190806',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097233161.jpg?alt=media&token=72e9b802-a55a-4c88-b75f-3dde30f8f29b',
          environmentsTrees: [

          ],
          rrule: null,
        },
        {
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102068584.jpg?alt=media&token=9d9b48a1-a3f1-4e4e-882b-5119f7c7e47f',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565131399038.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCmqbuUpwVVKKph',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190806',
        },
        {
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565122831752.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCuNhQzVIJz5jig',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190806',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102317644.jpg?alt=media&token=3ff6ccf3-c06e-40c7-8846-6a82e37ca10f',
          environmentsTrees: [

          ],
          rrule: null,
        },
      ],
      reminders: [
        {
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWzqKruXnvl15Wi': {
              date: '20190806',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190806',
          exclusions: [

          ],
        },
        {
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX6tblTEXDvl7AE': {
              date: '20190806',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190806',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
        },
      ],
      treeLogs: [
        {
          values: {
            height: '11',
          },
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190806',
          environmentsTrees: [

          ],
          note: 'Trimmings have been random and only a few days a part. Plants seem to be handling it well.',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190812: {
      photos: [
        {
          id: '-LnTGKCZ3u8imfDfcSEQ',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190812',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097201680.jpg?alt=media&token=9ec253e6-e64f-4014-b277-dc05545a5654',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565670090475.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565669944762.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCmqbuUpwVVKKpl',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190812',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102081649.jpg?alt=media&token=f1fedc4f-491b-449c-9c0c-e00d4ff9b4b0',
        },
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190812',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102084267.jpg?alt=media&token=a488a300-b359-4f1b-b256-714d90b8f3bf',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565669997136.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCmqbuUpwVVKKpn',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          id: '-LnTGKCqxx7xXoFi9Rr-',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190812',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102225863.jpg?alt=media&token=f5189068-f835-418b-b229-b27d4470f8d2',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565670019971.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190812',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX2jDMWW52COPgM': {
              date: '20190812',
            },
          },
          _type: 'reminders',
        },
      ],
      treeLogs: [
        {
          environments: [

          ],
          date: '20190812',
          environmentsTrees: [

          ],
          note: 'Have been bending the top 2 colas to see if smaller colas come off it due to it not being able to grow any taller.',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
        },
      ],
    },
    20190723: {
      photos: [
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190723',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097219800.jpg?alt=media&token=f662d2fa-8966-440a-b224-67c819c2d6f5',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563899677450.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKC_ai2couQhQB-z',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190723',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102160183.jpg?alt=media&token=5341eb9f-104f-4553-91e3-49f66713625d',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564112653790.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCob27kxKEBeafm',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          id: '-LnTGKCsnMLsfAHolyfz',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190723',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102265619.jpg?alt=media&token=8033c6bc-9a21-44a4-9baf-1bd79aa20ac5',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564112666617.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564112637099.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCuNhQzVIJz5jif',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190723',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102315286.jpg?alt=media&token=9ca0c70c-24e4-4694-984a-7dd94dc32b98',
        },
      ],
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX2jDMWW52COPgU': {
              date: '20190723',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190723',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXCcQQPoN4Xg8wu': {
              date: '20190723',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190723',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
        },
      ],
    },
    20190824: {
      photos: [
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190824',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097222590.jpg?alt=media&token=edc6ddcc-0735-47d3-9c97-f405db01070d',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566668520204.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKC_ai2couQhQB0-',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190824',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102152411.jpg?alt=media&token=96145693-93d4-4e58-b5ac-e06e4f0af0be',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566668538871.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCnE8qXNumfCTtx',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190824',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102157835.jpg?alt=media&token=2f6dff34-f573-49f4-9a91-e003c85adb84',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566668530440.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCob27kxKEBeafl',
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566751965436.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCv3DYVgegz4nwn',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190824',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102332318.jpg?alt=media&token=6d299bc3-d7fa-46df-9f97-c7571ba8a1a1',
        },
      ],
      reminders: [
        {
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXBhk4KJIfkunKW': {
              date: '20190824',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190824',
          exclusions: [

          ],
          futureExclusions: [

          ],
        },
      ],
      treeLogs: [
        {
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190824',
          environmentsTrees: [

          ],
          note: 'Hermie plant.. trimmed off the visible bananas',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190817: {
      photos: [
        {
          id: '-LnTGKC_ai2couQhQB01',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190817',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097229620.jpg?alt=media&token=464281c5-8055-424b-9896-2e2d583ba062',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566102365371.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190817',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097270693.jpg?alt=media&token=de8bfb66-72a3-4167-aff3-9d43bb46a1ac',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566102346722.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCaiR-koExqr13n',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
      ],
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190817',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWunD2RbFPoL1s4': {
              date: '20190817',
            },
          },
          _type: 'reminders',
        },
      ],
    },
    20190727: {
      photos: [
        {
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564266350939.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKC_ai2couQhQB04',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190727',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097238151.jpg?alt=media&token=8889d026-357b-4be0-98a3-b14f213c047f',
          environmentsTrees: [

          ],
          rrule: null,
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190727',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102070983.jpg?alt=media&token=5265aef4-0e9d-4f56-a685-a0a3b4607323',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564266364975.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCmqbuUpwVVKKpi',
        },
        {
          id: '-LnTGKCp-inGVRo5b7oy',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190727',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102183966.jpg?alt=media&token=82379495-bb3f-48a3-908a-2179174b1b91',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564266396407.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190727',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102250630.jpg?alt=media&token=939cf94b-cbdc-4b3f-8c11-1d581c4ed4e8',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564266460597.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCrNAyHLli8NCc6',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX8RAYgidhfS_XS': {
              date: '20190727',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190727',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
      ],
    },
    20190820: {
      photos: [
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190820',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097240831.jpg?alt=media&token=509d4a93-f8f7-4828-84d4-74c11d975c45',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566340831291.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKC_ai2couQhQB05',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          id: '-LnTGKCaiR-koExqr13j',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190820',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097256265.jpg?alt=media&token=b1bc7fb1-ea00-4bca-86ae-954bbae281cd',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566340811816.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102210758.jpg?alt=media&token=9b4481a4-2de4-4110-a372-cad0723aa387',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566340848464.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCqxx7xXoFi9Rqu',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190820',
        },
        {
          id: '-LnTGKCqxx7xXoFi9Rqx',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190820',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102220360.jpg?alt=media&token=a9c167c6-b0c8-4db5-b62a-6ac497abb564',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566340840681.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          id: '-LnTGKCrNAyHLli8NCc0',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190820',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102232713.jpg?alt=media&token=c9238c54-8aa0-4eb7-b1f8-8cac6998d68b',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566340822820.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      reminders: [
        {
          hasRrule: false,
          logs: {
            '-LnTGMWyOugOSznWv2I8': {
              date: '20190820',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190820',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190828: {
      photos: [
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190828',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097259873.jpg?alt=media&token=267607ae-3d52-4652-b4c5-2f3bde1ba55b',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1567017779237.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCaiR-koExqr13k',
        },
        {
          active: true,
          id: '-LnTGKCnE8qXNumfCTtr',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190828',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102089912.jpg?alt=media&token=4c84ae27-5532-4c1b-928b-d73dfd07e95b',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1567017768308.png',
          source: 'PhotoSourceEnum.url',
        },
      ],
      reminders: [
        {
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX7e97p9xn2mfhO': {
              date: '20190828',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190828',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
        },
      ],
    },
    20190716: {
      photos: [
        {
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190716',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097295047.jpg?alt=media&token=cb2eed14-f060-4b69-911e-b9c61b7980c0',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667817000.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCbS6HtWNENSctB',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
        },
      ],
    },
    20190815: {
      photos: [
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565906011334.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCbS6HtWNENSctC',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190815',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097298441.jpg?alt=media&token=ed9f6267-95b3-4646-aa9a-efb1be78a0bd',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190815',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102263156.jpg?alt=media&token=a1c1b485-ecd5-424c-af97-c78d0e7372f4',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565906024595.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCsnMLsfAHolyfy',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190815',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102273509.jpg?alt=media&token=d6d07731-ef14-4708-ac1c-e28e209b1525',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1565905971824.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCsnMLsfAHolyg1',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
      reminders: [
        {
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWwieQcLTGxibi4': {
              date: '20190815',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190815',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
        },
      ],
      treeLogs: [
        {
          date: '20190815',
          environmentsTrees: [

          ],
          note: "It's female!! Did a random check today around 4:30pm and noticed the pistils like on the OG! Checked 3 days ago and didnt see signs. Check top colas, not bottom ones!",
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
        },
      ],
    },
    20190715: {
      photos: [
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563668600592.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCc_oo-JtUWxlww',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190715',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097382115.jpg?alt=media&token=40735ac2-0350-4952-a8a7-ad27337a76eb',
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: 'Could have had it closer to lights. Was afraid of burning\/bleaching so it stretched for the lights.. ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190715',
        },
      ],
    },
    20190712: {
      photos: [
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190712',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097387911.jpg?alt=media&token=bbe99f75-099f-4abe-b623-cb7d83671f75',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667975936.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCc_oo-JtUWxlwy',
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667699435.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCjgzXiFVGk95L5',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190712',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567101957557.jpg?alt=media&token=572aaecc-d8d3-412a-9153-1263ab2d0d7a',
        },
      ],
    },
    20190714: {
      photos: [
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190714',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097664669.jpg?alt=media&token=4231ad7d-35e7-497a-85a5-262cbc1a6536',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667777090.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCdib7VjbLNtuo2',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190728: {
      photos: [
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564339711637.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCdib7VjbLNtuo7',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190728',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097677199.jpg?alt=media&token=c764c1dc-ff8f-4488-b907-00d7d58e97dc',
        },
        {
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190728',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567101981668.jpg?alt=media&token=05b34c5f-087f-4393-804e-63e051e7da49',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1564339734902.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCkaD7hpPYCx_I-',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
        },
      ],
      reminders: [
        {
          hasRrule: false,
          logs: {
            '-LnTGMX-rBFMvUHkxuid': {
              date: '20190728',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190728',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWwieQcLTGxibhy': {
              date: '20190728',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190728',
        },
      ],
    },
    20190719: {
      photos: [
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563573439797.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCiTGQv2VWIOnqu',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190719',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097764393.jpg?alt=media&token=c95523ff-78ef-4c0a-898c-13c0451d2f88',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190719',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567101937345.jpg?alt=media&token=a05d85b0-a649-432c-afb8-9be69ac1d686',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563552039956.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCiTGQv2VWIOnqy',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          id: '-LnTGKCmqbuUpwVVKKpj',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190719',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102073476.jpg?alt=media&token=ad039c71-bbb4-4d78-a55e-16cb8d9449c9',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563552090811.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      reminders: [
        {
          type: 'ReminderTypeEnum.trim',
          date: '20190719',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Topped',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX9o1Ivm2SKngq9': {
              date: '20190719',
            },
          },
          _type: 'reminders',
          environments: [

          ],
        },
      ],
    },
    20190827: {
      photos: [
        {
          id: '-LnTGKCiTGQv2VWIOnqx',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190827',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567097773755.jpg?alt=media&token=08344a5f-2220-4a99-8174-17daf32e6a87',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566939727460.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566939755819.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCrNAyHLli8NCc4',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190827',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102242602.jpg?alt=media&token=aa2007d8-f8d5-4600-a88a-14d80c7ef7c4',
        },
      ],
      reminders: [
        {
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWx--LKXjM7iv2q': {
              date: '20190827',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190827',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
        },
      ],
    },
    20190720: {
      photos: [
        {
          id: '-LnTGKCjgzXiFVGk95L4',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190720',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567101952292.jpg?alt=media&token=3aa61bc4-4142-41f8-a623-4fbd67acb8b3',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563683074723.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX-rBFMvUHkxuiV': {
              date: '20190720',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190720',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
      ],
    },
    20190822: {
      photos: [
        {
          id: '-LnTGKCjgzXiFVGk95LB',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190822',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567101971853.jpg?alt=media&token=38e5445e-ba3f-4ff4-aca3-6737e90a62cf',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566499300605.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190822',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102006689.jpg?alt=media&token=bf7db537-868c-4bfe-baa9-c4bdd139a24c',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1566499282831.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKClWKpwtR1-6peB',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
      ],
      reminders: [
        {
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX7e97p9xn2mfhD': {
              date: '20190822',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190822',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
        },
      ],
    },
    20190707: {
      photos: [
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190707',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567101989152.jpg?alt=media&token=4cdb8a59-ae06-4626-962b-d813fb8ba6ca',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667670253.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKCkaD7hpPYCx_I2',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190707',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102009405.jpg?alt=media&token=64108f59-50fe-4e0d-8fe4-b1a77ba3df8b',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667662426.png',
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnTGKClWKpwtR1-6peD',
        },
      ],
    },
    20190702: {
      photos: [
        {
          id: '-LnTGKClWKpwtR1-6peG',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190702',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102016304.jpg?alt=media&token=e41d901e-1dc1-4719-a847-a3efaf43fdac',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563667524786.png',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
    },
    20190717: {
      photos: [
        {
          id: '-LnTGKDpxPlQkU_9sLpH',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
            '-LnTGKYcU5KH0_KQCHNW',
          ],
          _type: 'photos',
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.environment',
          date: '20190717',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102322742.jpg?alt=media&token=ddaab9c2-f899-4a0f-a2f7-27933f3fa83a',
          environmentsTrees: {
            '-LnTGKDnoDjtQ2-PPSpx': [
              '-LnTGKYZ19xOEKggeg8w',
              '-LnTGKYcU5KH0_KQCHNW',
            ],
          },
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563417440277.png',
          mainEnvironment: '-LnTGKDnoDjtQ2-PPSpx',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          id: '-LnTGKDpxPlQkU_9sLpH',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
            '-LnTGKYcU5KH0_KQCHNW',
          ],
          _type: 'photos',
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          mainTree: [

          ],
          type: 'PhotoTypeEnum.environment',
          date: '20190717',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567102322742.jpg?alt=media&token=ddaab9c2-f899-4a0f-a2f7-27933f3fa83a',
          environmentsTrees: {
            '-LnTGKDnoDjtQ2-PPSpx': [
              '-LnTGKYZ19xOEKggeg8w',
              '-LnTGKYcU5KH0_KQCHNW',
            ],
          },
          rrule: null,
          originalPath: '\/data\/user\/0\/com.unlogical.jane\/files\/1563417440277.png',
          mainEnvironment: '-LnTGKDnoDjtQ2-PPSpx',
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      reminders: [
        {
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Repelent',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX4P1aNege1Z8rv': {
              date: '20190717',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.repelent',
          date: '20190717',
          exclusions: [

          ],
        },
        {
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWzqKruXnvl15Wj': {
              date: '20190717',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190717',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
        },
      ],
      treeLogs: [
        {
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190717',
          environmentsTrees: [

          ],
          note: 'Transferred to 4" pot ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190724: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190724',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX8RAYgidhfS_XU': {
              date: '20190724',
            },
          },
          _type: 'reminders',
        },
      ],
    },
    20190729: {
      reminders: [
        {
          type: 'ReminderTypeEnum.watering',
          date: '20190729',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWx--LKXjM7iv2r': {
              date: '20190729',
            },
          },
          _type: 'reminders',
          environments: [

          ],
        },
      ],
    },
    20190731: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX-rBFMvUHkxuia': {
              date: '20190731',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.nutrients',
          date: '20190731',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Nutrients',
          notificationTime: null,
        },
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXBhk4KJIfkunKT': {
              date: '20190731',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190731',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
      ],
      treeLogs: [
        {
          active: false,
          environments: [

          ],
          date: '20190731',
          environmentsTrees: [

          ],
          note: null,
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: {
            height: '10.5',
          },
          _type: 'treeLogs',
        },
      ],
    },
    20190808: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX2jDMWW52COPgO': {
              date: '20190808',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190808',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
      ],
    },
    20190816: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190816',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWx--LKXjM7iv2w': {
              date: '20190816',
            },
          },
          _type: 'reminders',
        },
      ],
      treeLogs: [
        {
          environments: [

          ],
          date: '20190816',
          environmentsTrees: [

          ],
          note: 'Keep up with watering. Check pot weight',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
        },
      ],
    },
    20190803: {
      reminders: [
        {
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX-rBFMvUHkxui_': {
              date: '20190803',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190803',
          exclusions: [

          ],
        },
        {
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWzqKruXnvl15Wg': {
              date: '20190803',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190803',
        },
      ],
    },
    20190718: {
      reminders: [
        {
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX0iStfCO76E9tG': {
              date: '20190718',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190718',
        },
      ],
    },
    20190722: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXBhk4KJIfkunKQ': {
              date: '20190722',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190722',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXDDFcvgopZZjfx': {
              date: '20190722',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.repelent',
          date: '20190722',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Repelent',
          notificationTime: null,
        },
      ],
      treeLogs: [
        {
          values: {
            height: '8',
          },
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190722',
          environmentsTrees: [

          ],
          note: 'Multiple gnats in and around soil',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190726: {
      reminders: [
        {
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX54t9-sqhXtJrD': {
              date: '20190726',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190726',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
        },
        {
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Repelent',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXAGxWs3rdktddq': {
              date: '20190726',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.repelent',
          date: '20190726',
          exclusions: [

          ],
          futureExclusions: [

          ],
        },
        {
          name: 'Nutrients',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWx--LKXjM7iv3-': {
              date: '20190726',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.nutrients',
          date: '20190726',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: 'Water bottle with 1 food spike added to it. Split between the 4 plants ',
          isDone: true,
          rrule: null,
          active: false,
        },
      ],
    },
    20190819: {
      reminders: [
        {
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMXBhk4KJIfkunKZ': {
              date: '20190819',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190819',
        },
      ],
    },
    20190725: {
      reminders: [
        {
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX3m6YN-BaszRBv': {
              date: '20190725',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190725',
          exclusions: [

          ],
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: null,
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: {
            height: '8',
          },
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190725',
        },
      ],
    },
    20190730: {
      reminders: [
        {
          name: 'Repelent',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX54t9-sqhXtJrO': {
              date: '20190730',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.repelent',
          date: '20190730',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
        },
        {
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWx--LKXjM7iv2t': {
              date: '20190730',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190730',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
        },
      ],
    },
    20190810: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190810',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWwieQcLTGxibi5': {
              date: '20190810',
            },
          },
          _type: 'reminders',
        },
        {
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX3m6YN-BaszRC5': {
              date: '20190810',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190810',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
        },
      ],
    },
    20190811: {
      reminders: [
        {
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX3m6YN-BaszRBz': {
              date: '20190811',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190811',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
        },
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX6tblTEXDvl7AJ': {
              date: '20190811',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190811',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
        },
      ],
    },
    20190813: {
      reminders: [
        {
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWunD2RbFPoL1s5': {
              date: '20190813',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190813',
          exclusions: [

          ],
          futureExclusions: [

          ],
        },
      ],
    },
    20190823: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190823',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMX1MNsaaYh6DVJO': {
              date: '20190823',
            },
          },
          _type: 'reminders',
        },
      ],
    },
    20190825: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnTGMWx--LKXjM7iv2p': {
              date: '20190825',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190825',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: 'Made sure to water enough for it to come out of the bottom! ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190825',
        },
      ],
    },
    20190805: {
      reminders: [
        {
          hasRrule: false,
          logs: {
            '-LnTGMXAGxWs3rdktddp': {
              date: '20190805',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190805',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: true,
          rrule: null,
          active: false,
          name: 'Watering',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190829: {
      photos: [
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190829',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567137287164.jpg?alt=media&token=18ec9707-c69d-4596-b027-96adf7e5f117',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnVeJddO3zjzSxXz6oQ',
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190829',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567137291807.jpg?alt=media&token=2ede9d1f-87d5-49d5-b6b8-81298816fa7f',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnVeJebSTfaH2otsY7w',
        },
        {
          id: '-LnVeJfEJGVX-oB-h8Bd',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190829',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567137289597.jpg?alt=media&token=0ec9488a-0354-4c7f-a53f-d5a7e081b62b',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      environmentChanges: [
        {
          date: '20190829',
          environment: {
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '90',
              },
            ],
            name: 'Grow Box',
          },
          changes: [
            'EnvironmentChangeType.new_setup',
          ],
          _type: 'environmentChanges',
          active: true,
        },
        {
          original_environment: {
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
            lights: [
              {
                wattage: '90',
                type: 'LightingTypeEnum.led',
              },
            ],
          },
          changes: [

          ],
          date: '20190829',
          environment: {
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '90',
              },
            ],
            name: 'Grow Box',
          },
          _type: 'environmentChanges',
          active: true,
        },
        {
          date: '20190829',
          environment: {
            exposureTime: 12,
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '90',
              },
            ],
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
          },
          original_environment: {
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '90',
              },
            ],
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
          },
          changes: [

          ],
          _type: 'environmentChanges',
          active: true,
        },
        {
          original_environment: {
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '90',
              },
            ],
            name: 'Grow Box',
          },
          changes: [

          ],
          date: '20190829',
          environment: {
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '90',
              },
            ],
          },
          _type: 'environmentChanges',
          active: true,
        },
      ],
    },
    20190830: {
      reminders: [
        {
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: 'Thorough watering!',
          isDone: false,
          rrule: null,
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnZFECS_lk1u5ib0LuZ': {
              date: '20190830',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190830',
          exclusions: [

          ],
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: 'Noticing tiny orange hairs',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190830',
        },
      ],
    },
    20190831: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LncuOLvP7IxUf5sE4EH': {
              date: '20190831',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.repelent',
          date: '20190831',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: 'Multiple gnats at top of soil. Spray is watered down now',
          isDone: false,
          rrule: null,
          active: false,
          name: 'Repellent',
          notificationTime: null,
        },
      ],
      photos: [
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190831',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567283157556.jpg?alt=media&token=d1d7c486-9ebd-4a42-a747-648278efc4a6',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LndLlYCzxFh8CpgEzdd',
        },
        {
          environments: [

          ],
          mainTree: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190831',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567283171833.jpg?alt=media&token=cb3f2f52-8eea-4ad6-9dcb-5f6a93ebfedd',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LndLp1oDUPnD72Zij6_',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190831',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567283178971.jpg?alt=media&token=5bec93a1-9ca7-4669-b333-b3315121b191',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LndLqmJlit8FD4CBV66',
        },
      ],
    },
    20190902: {
      reminders: [
        {
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LnnRxNn2ocFm9JVDosN': {
              date: '20190902',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190902',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
        },
      ],
      treeLogs: [
        {
          active: false,
          environments: [

          ],
          date: '20190902',
          environmentsTrees: [

          ],
          note: 'No signs of gnat larvae after soil dried ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
        },
      ],
    },
    20190903: {
      photos: [
        {
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnrXx8Pl0Tzi7_CuCrh',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190903',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567521231777.jpg?alt=media&token=5c7ccf90-18fe-4f13-b484-001f30171e73',
          environmentsTrees: [

          ],
          rrule: null,
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190903',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567539487048.jpg?alt=media&token=831604d2-8195-44a8-ac46-03c98f63f753',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-Lnsc_POPb9tUO6WTVXx',
        },
        {
          id: '-Lnsc_Q7CcybBEXXQgE_',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190903',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567539489630.jpg?alt=media&token=d28b78b6-65f7-446a-b966-d4934a93c5b0',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190903',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567539484681.jpg?alt=media&token=f0170b11-110f-494e-95fc-212393dee4f9',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-Lnsc_QXveEPJDIoFtSs',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          active: true,
          id: '-Lnsc_ROc66dwXEWeVRp',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190903',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567539492052.jpg?alt=media&token=ff0846cc-df7c-49bb-960a-f490a13960e0',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
        },
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190903',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567539588780.jpg?alt=media&token=99445b04-4d42-4f06-a65b-13c550f696f5',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnscypYUZwLpq7cDvIc',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: "Don't water everyday to keep down on the gnats",
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190903',
        },
      ],
    },
    20190901: {
      treeLogs: [
        {
          active: false,
          environments: [

          ],
          date: '20190901',
          environmentsTrees: [

          ],
          note: 'Pistils are definitely turning orange',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
        },
      ],
    },
    20190904: {
      photos: [
        {
          note: 'Took some natural light pictures!',
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190904',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567628920843.jpg?alt=media&token=6be6bd9f-1df7-4532-b056-070eda7b2131',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnxxkPi3LIXy-nTUAmw',
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnxxkQU0amDVEhVzMb4',
          note: 'Took some natural light pictures!',
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190904',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567628923387.jpg?alt=media&token=5ec1a51f-c6ab-4691-bdfa-5f483cad3885',
        },
        {
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnxxkRBE5aDrwzyyVFq',
          note: 'Took some natural light pictures!',
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190904',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567628925440.jpg?alt=media&token=f1ffb14b-dbcd-4abf-9007-7a602252cc7b',
          environmentsTrees: [

          ],
          rrule: null,
        },
        {
          id: '-LnxxkTibVfLfOWwjuJv',
          note: 'Took some natural light pictures!',
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190904',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567628927380.jpg?alt=media&token=4b0f5164-855e-4105-aaae-d1961c9ef2dd',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190904',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567628929982.jpg?alt=media&token=771ca1fb-fa86-40dd-91d4-73388e4a0924',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LnxxkUuJMQRlzKBakOK',
          note: 'Took some natural light pictures!',
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
      ],
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190904',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-Lo1VtJlPDpVHw7cxvmn': {
              date: '20190904',
            },
          },
          _type: 'reminders',
        },
      ],
    },
    20190905: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190905',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-Lo1VmGOeyye7_UjGx0Z': {
              date: '20190905',
            },
          },
          _type: 'reminders',
        },
      ],
      treeLogs: [
        {
          environments: [

          ],
          date: '20190905',
          environmentsTrees: [

          ],
          note: 'Toothpick was actually completely dry so went ahead and did a good watering',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
        },
        {
          environmentsTrees: [

          ],
          note: "Hairs on the top buds are definitely turning orange and buds are forming more and more each day. Shouldn't be long before all the white pistils are gone and have just buds there",
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190905',
        },
      ],
      photos: [
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190905',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567735512621.jpg?alt=media&token=5d5bceb4-2ca7-4f8e-8794-a0e3c2ceb6d0',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-Lo3JMmvN9vmw94QjAxS',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          id: '-Lo3JMnkSlNoiBzXMa3Y',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190905',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567735517527.jpg?alt=media&token=6f383948-a697-4169-bf81-46cac33f90b3',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567735514980.jpg?alt=media&token=1279d34b-9ac7-4f61-bd40-74bb37d7accf',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-Lo3JMoCSa-ntAWJRoOk',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190905',
        },
      ],
    },
    20190906: {
      reminders: [
        {
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-Lo8Mhgi_7lz3fylL052': {
              date: '20190906',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190906',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
        },
      ],
      photos: [
        {
          active: true,
          id: '-Lo974Zjnav0bmPSTl-t',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190906',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567832955548.jpg?alt=media&token=d4c87e56-5dc9-4a93-9b42-f62bcdf41bba',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190906',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567832958350.jpg?alt=media&token=2ab7d65e-743e-4df6-bf29-c8bbe586cba8',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-Lo974_hciuEibTzTB4R',
        },
        {
          id: '-Lo974aBdc66cz6kb5LT',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190906',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567832960911.jpg?alt=media&token=4583945b-bf17-45b9-991d-e4f948c0a497',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-Lo974bRxtE5xtpnxa1L',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190906',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567832963369.jpg?alt=media&token=95890b36-cca4-4887-ac3e-c4ccb8e1dc2b',
          environmentsTrees: [

          ],
          rrule: null,
        },
      ],
      environmentChanges: [
        {
          environment: {
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '25',
              },
            ],
          },
          original_environment: {
            lights: [
              {
                wattage: '90',
                type: 'LightingTypeEnum.led',
              },
            ],
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 12,
          },
          changes: [
            'EnvironmentChangeType.light_change',
          ],
          date: '20190906',
          _type: 'environmentChanges',
          active: true,
        },
      ],
    },
    20190908: {
      reminders: [
        {
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LoIJ20TBQTGy1F712gn': {
              date: '20190908',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190908',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
        },
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.nutrients',
          date: '20190908',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
          name: 'Nutrients',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LoIJ29KgvRTUi17JZsM': {
              date: '20190908',
            },
          },
          _type: 'reminders',
        },
      ],
      photos: [
        {
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190908',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567987180183.jpg?alt=media&token=42852344-af4c-410f-b0fa-1b38d40d3972',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LoIJP46ODgMT1J6T6C1',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LoIJP4c7wG1DbPQ6OtK',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190908',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567987183279.jpg?alt=media&token=9119a4d7-a2a1-44cc-9a81-3639c0bbb5e3',
        },
        {
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1567987186222.jpg?alt=media&token=5ca8de02-145e-4cea-8d0d-6ccbae0a0376',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '-LoIJP5Qtkl1dphANFp7',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190908',
        },
      ],
      treeLogs: [
        {
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190908',
          environmentsTrees: [

          ],
          note: 'Stems to leaves are purple and purple streaks up main stem. Was told its a ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
        },
      ],
    },
    20190909: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '-LoO4NIzgwJAc53gL9su': {
              date: '20190909',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190909',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
          name: 'Water',
          notificationTime: null,
        },
      ],
      treeLogs: [
        {
          date: '20190909',
          environmentsTrees: [

          ],
          note: 'Spots on leaves are getting worse..',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
        },
      ],
    },
    20190923: {
      photos: [
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '0YV0tvuHEPfF4teFJKTB',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190923',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569379312633.jpg?alt=media&token=b42bcea0-c492-48da-b9e8-3c5249cdf2f0',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190923',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569379310059.jpg?alt=media&token=572ad50b-a3dc-40ea-aa75-351226b4bce8',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '7ujwbzA9eSI0D5W9zos8',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'WTqZV7AOxWylnMPgsep1',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190923',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569379314900.jpg?alt=media&token=cab59e02-4c55-4767-a093-024ba60c4426',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190923',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569379316954.jpg?alt=media&token=8e9562b6-fe38-439b-908f-af9c13d8ca87',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'XXBMdUKpLwj8ydHStueT',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190923',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569379319579.jpg?alt=media&token=faa6696c-a8d2-4a6f-a36c-b9d275c2a70f',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'nOjqzox4snOvyTjOJoWp',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
      ],
    },
    20190917: {
      reminders: [
        {
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: false,
          rrule: null,
          active: true,
          name: 'Water',
          notificationTime: null,
          ignoredTrees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          trees: [
            '-LnTGKYQwFm_2gsJawnV',
            '-LnTGKYXnPQLQLnV9ElX',
            '-LnTGKYZ19xOEKggeg8w',
            '-LnTGKYcU5KH0_KQCHNW',
          ],
          hasRrule: false,
          logs: [

          ],
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190917',
          exclusions: [

          ],
        },
      ],
      treeLogs: [
        {
          environments: [

          ],
          date: '20190917',
          environmentsTrees: [

          ],
          note: 'Let the soil dry out again. Gnats were getting bad. Soil dried out so much that the leaves were droopy. Gave it some water and it perked back up',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
        },
      ],
    },
    20190925: {
      photos: [
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536149432.jpg?alt=media&token=a1a16ac4-80e8-4de1-a50f-7336f01a79c5',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: '5U5wTxhme5AzrGTpXc4C',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          id: '7ZMu8TP2swpK2Jl6VOrH',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536152612.jpg?alt=media&token=4de8d234-dcbd-4d11-a76e-affad7e23e21',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          id: 'IoEL7hGyMpoE2cmLgoIS',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536155493.jpg?alt=media&token=61351b06-c67d-4aad-a989-86559093b1a2',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536158439.jpg?alt=media&token=4bf13509-74ec-49aa-a595-d7a469378bdf',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'LHzZYIhH2RfqcgWpNLbG',
        },
        {
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536161415.jpg?alt=media&token=ae17e934-5a80-4a3d-8d7d-f13a7c4901d6',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'TKOY99xQ0aZkG7S6DuU8',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
        },
        {
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'WUOunEUCwVXkauAKY6Uy',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536146011.jpg?alt=media&token=abfda14d-ae38-4efb-af1b-72d1b63872a8',
          environmentsTrees: [

          ],
          rrule: null,
        },
        {
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536164185.jpg?alt=media&token=e0c47bfd-596c-43fe-93f5-164387ed5929',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'e3iiniqijWNvidtowj3a',
        },
        {
          id: 'olIzP12bkyvnIRV1Dgf2',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536166647.jpg?alt=media&token=3b46b70e-0419-428c-aa05-a34e28eea15c',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          id: 'q5uaFgL3Ww499V1VMasW',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190925',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569536168999.jpg?alt=media&token=d348d2cf-78a1-4181-b838-655534dd85ce',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
      ],
      treeLogs: [
        {
          active: false,
          environments: [

          ],
          date: '20190925',
          environmentsTrees: [

          ],
          note: 'Hoping for 5-6g of dry bud! Learned a lot through this for sure. Head feels like it might explode..',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
        },
      ],
    },
    20190921: {
      reminders: [
        {
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: [

          ],
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190921',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: false,
          rrule: null,
          active: false,
          name: 'Water',
          notificationTime: null,
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: 'Last watering before harvest! 5 days to go! Still plan on doing it at 8 weeks to ke',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190921',
        },
      ],
    },
    20190918: {
      treeLogs: [
        {
          date: '20190918',
          environmentsTrees: [

          ],
          note: 'Still some discoloration happening on what are now the oldest leaves since I clipped the fan leaves that ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
        },
      ],
      photos: [
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'UihCn6aFAeUbcTE1yH8n',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190918',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568833370532.jpg?alt=media&token=a9137a8c-3263-4041-807e-d7c53ad3bd27',
        },
        {
          id: 'g5uZFt3HkxOFeAQTXHYp',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190918',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568833373180.jpg?alt=media&token=ba80d6e9-2da2-4618-a61d-21abe6b7846e',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190918',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568833375317.jpg?alt=media&token=1cbb7322-73ec-4e2f-8686-5687cff68eb2',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 's72ZmPIm0yhUIcHAxGbp',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'tRPbIfaIQHTRYQDXsOLi',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190918',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568833377520.jpg?alt=media&token=e181442f-6801-4a00-a063-4f1069dbbb25',
        },
      ],
      reminders: [
        {
          name: 'Water',
          notificationTime: null,
          ignoredTrees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          trees: [
            '-LnTGKYQwFm_2gsJawnV',
            '-LnTGKYZ19xOEKggeg8w',
            '-LnTGKYXnPQLQLnV9ElX',
          ],
          hasRrule: false,
          logs: [

          ],
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190918',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: false,
          rrule: null,
          active: true,
        },
      ],
    },
    20190913: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.trim',
          date: '20190913',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
          name: 'Trim',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            '00nWgXH31gQOftgPJUFy': {
              date: '20190913',
            },
          },
          _type: 'reminders',
        },
        {
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            NcsABBAn7Xb0gXgVF9bo: {
              date: '20190913',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190913',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
        },
      ],
      photos: [
        {
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568419188111.jpg?alt=media&token=205fd549-079e-44cb-b882-d7847eabd2da',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'OZga5dwy4cGfzSIfcM94',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
          environments: [

          ],
          mainTree: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          type: 'PhotoTypeEnum.tree',
          date: '20190913',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190913',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568419108503.jpg?alt=media&token=dfbbd2f9-7788-4432-9cd5-f2ba0a78045e',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'pP2ngsRlvOBV8tNjcS5g',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
        {
          environments: [

          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190913',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1568419105847.jpg?alt=media&token=acc3086f-1054-49e3-8697-5879d6e04f48',
          environmentsTrees: [

          ],
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
          active: true,
          id: 'ywRJb8oBBHrOJzoroi1j',
          note: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          _type: 'photos',
        },
      ],
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: 'Deficiency was making the older fan leaves droop, which means they were dying and going to fall off soon. Trimmed those off and hoping this last 2 weeks goes smoothly',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190913',
        },
      ],
    },
    20190910: {
      treeLogs: [
        {
          environments: [

          ],
          date: '20190910',
          environmentsTrees: [

          ],
          note: 'After someone finally breaking through all the idiots.. I have a very clear phosphorus deficiency. Planning on harvesting a week early ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
        },
      ],
    },
    20190911: {
      reminders: [
        {
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190911',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            vYBch8F3nopx1KO3saRN: {
              date: '20190911',
            },
          },
          _type: 'reminders',
        },
      ],
    },
    20190924: {
      treeLogs: [
        {
          environments: [

          ],
          date: '20190924',
          environmentsTrees: [

          ],
          note: 'It has used up all the water in the pot and is beginning to dry out some!',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
        },
        {
          environmentsTrees: [

          ],
          note: 'Upon a further investigation with microscope.. found some trichomes that were turning amber! Thought most of them were clear so will need to try to focus in better at different angles ',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190924',
        },
      ],
    },
    20190915: {
      reminders: [
        {
          active: false,
          name: 'Water',
          notificationTime: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          hasRrule: false,
          logs: {
            dHkKIA4o8hpulOXIfTvA: {
              date: '20190915',
            },
          },
          _type: 'reminders',
          environments: [

          ],
          type: 'ReminderTypeEnum.watering',
          date: '20190915',
          exclusions: [

          ],
          futureExclusions: [

          ],
          environmentsTrees: [

          ],
          description: null,
          isDone: null,
          rrule: null,
        },
      ],
    },
    20190912: {
      treeLogs: [
        {
          environmentsTrees: [

          ],
          note: 'So the "sprinkle and water" nutrients I added to the platinum seems to be slowing down the leaf deterioration.',
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
          active: false,
          environments: [

          ],
          date: '20190912',
        },
      ],
    },
    20190922: {
      treeLogs: [
        {
          active: false,
          environments: [

          ],
          date: '20190922',
          environmentsTrees: [

          ],
          note: "Trichomes will still be mostly clear from what it looks like. Don't really want to stretch this out any farther",
          rrule: null,
          trees: [
            '-LnTGKYZ19xOEKggeg8w',
          ],
          values: [

          ],
          _type: 'treeLogs',
        },
      ],
    },
    20191003: {
      environmentLogs: [
        {
          note: null,
          rrule: null,
          values: {
            humidity: '60',
            envTemperature: '76',
          },
          _type: 'environmentLogs',
          active: true,
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191003',
        },
      ],
    },
    20191004: {
      environmentLogs: [
        {
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191004',
          note: null,
          rrule: null,
          values: {
            humidity: '54',
            envTemperature: '77',
          },
          _type: 'environmentLogs',
          active: true,
        },
      ],
    },
    20190926: {
      photos: [
        {
          active: true,
          id: 'EaoPU9fL9ipVF3sAYHRR',
          note: null,
          trees: [
            '-LnTGKYcU5KH0_KQCHNW',
            'LqZYSDJBKUroZqwZSGso',
            'MwILa5Jg39DyGPV9sVvl',
          ],
          _type: 'photos',
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          mainTree: [

          ],
          type: 'null',
          date: '20190926',
          path: 'https:\/\/firebasestorage.googleapis.com\/v0\/b\/jane-14027.appspot.com\/o\/s2sWD75iyBZpvHxHcPgzCUUE6X72%2F1569540586550.jpg?alt=media&token=6cdc72d1-dbd3-4db8-875a-bd62a06a00a6',
          environmentsTrees: {
            '-LnTGKDnoDjtQ2-PPSpx': [
              '-LnTGKYcU5KH0_KQCHNW',
              'LqZYSDJBKUroZqwZSGso',
              'MwILa5Jg39DyGPV9sVvl',
            ],
          },
          rrule: null,
          originalPath: null,
          source: 'PhotoSourceEnum.url',
        },
      ],
    },
    20191002: {
      environmentLogs: [
        {
          note: 'So the original blocks were actually the best option from the start.. The main reason why I swapped them',
          rrule: null,
          values: [

          ],
          _type: 'environmentLogs',
          active: true,
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191002',
        },
        {
          _type: 'environmentLogs',
          active: true,
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191002',
          note: null,
          rrule: null,
          values: {
            humidity: '57',
            envTemperature: '74',
          },
        },
      ],
      environmentChanges: [
        {
          date: '20191002',
          environment: {
            lights: [
              {
                type: 'LightingTypeEnum.led',
                wattage: '30',
              },
            ],
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
            exposureTime: 24,
          },
          original_environment: {
            exposureTime: 12,
            lights: [
              {
                wattage: '25',
                type: 'LightingTypeEnum.led',
              },
            ],
            name: 'Grow Box',
            type: 'EnvironmentTypeEnum.indoor',
          },
          changes: [
            'EnvironmentChangeType.exposition_change',
            'EnvironmentChangeType.light_change',
          ],
          _type: 'environmentChanges',
          active: true,
        },
      ],
    },
    20191005: {
      environmentLogs: [
        {
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191005',
          note: null,
          rrule: null,
          values: {
            humidity: '60',
            envTemperature: '74',
          },
          _type: 'environmentLogs',
          active: true,
        },
      ],
    },
    20191006: {
      environmentLogs: [
        {
          _type: 'environmentLogs',
          active: true,
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191006',
          note: null,
          rrule: null,
          values: {
            envTemperature: '74',
            humidity: '62',
          },
        },
      ],
    },
    20191008: {
      environmentLogs: [
        {
          environments: [
            '-LnTGKDnoDjtQ2-PPSpx',
          ],
          date: '20191008',
          note: '12:56am',
          rrule: null,
          values: {
            humidity: '49',
            envTemperature: '77',
          },
          _type: 'environmentLogs',
          active: true,
        },
      ],
    },
  },
  views: 28,
};
