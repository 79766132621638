import React, { Suspense, useEffect, useState } from 'react';
import { Box, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { func, shape } from 'prop-types';
import queryString from 'query-string';
import Dropdown from '../../components/Dropdown';
import Loader from '../../components/Loader';
import { filterTypesForApi } from '../../utils/constants';
import useDebounce from '../../utils/hooks/useDebounce';
import useInfiniteScroll from '../../utils/hooks/useInfiniteScroll';
import useIsMobile from '../../utils/hooks/useIsMobile';
import sortOptions from '../../utils/sortOptions';
import filtersOptions from '../../utils/filters';
import Filters from './components/Filters';
import GrowlogsList from './components/List';
import { initGA, sendAnalytics, sendPageView } from '../../utils/analytics';

const ExploreGrowlogs = ({
  getGrowlogs,
  growlogs,
  clearGrowlogList,
  history,
  location: { search },
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const [openFilters, setOpenFilters] = useState(false);
  const [isFetching, setIsFetching] = useInfiniteScroll();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [sortBy, setSortBy] = useState(null);
  const debounceSearch = useDebounce(searchValue, 500);
  const isMobile = useIsMobile(600);

  const handleChange = ({ target: { value } }) => {
    setSearchValue(value);
    sendAnalytics({
      category: 'explore-growlogs',
      action: 'search',
      label: `Search ${value}`,
    });
  };

  const handleOpenFilters = () => setOpenFilters(!openFilters);

  const clearSearch = () => setSearchValue('');

  const clearFilters = (e) => {
    e.stopPropagation();
    setSelectedFilters([]);
  };

  const sendFilterEvent = (value) => {
    sendAnalytics({
      category: 'explore-growlogs',
      action: 'filter-selected',
      label: `User selected ${value}`,
    });
  };

  const handleFilters = ({ target: { value } }) => {
    let newFilters = selectedFilters.slice();
    sendFilterEvent(value);

    if (selectedFilters.includes(value)) {
      newFilters = newFilters.filter((filter) => filter !== value);
    } else if (value.indexOf('LightingTypeEnum') !== -1) {
      newFilters = selectedFilters.filter((filter) => filter.indexOf('LightingTypeEnum') === -1);
      newFilters.push(value);
    } else {
      newFilters.push(value);
    }

    setSelectedFilters(newFilters);
  };

  const getFilters = () => {
    const filters = selectedFilters.reduce((acc, curr) => {
      const filterType = curr.split('.', 1)[0];
      let oldFilterType;

      if (filterType !== 'LightingTypeEnum') {
        const currentFilterData = acc[filterTypesForApi[filterType]] || [];
        oldFilterType = [curr, ...currentFilterData];
      } else {
        oldFilterType = curr;
      }

      return {
        ...acc,
        [filterTypesForApi[filterType]]: oldFilterType,
      };
    }, {});

    return filters;
  };

  const getLabelSort = () => {
    const option = sortOptions.find(({ value }) => value === sortBy);

    return option ? option.label : 'Sort by';
  };

  const handleSortBy = (value) => {
    setSortBy(value);
    sendAnalytics({
      category: 'explore-growlogs',
      action: 'sort-selected',
      label: `User selected ${value}`,
    });
  };

  useEffect(() => {
    initGA();
    sendPageView('explore-growlogs');
    getGrowlogs({ page: 1 });
  }, []);

  useEffect(() => {
    const formatFilters = selectedFilters.map((filter) => filter.split('.')[1]);
    const query = queryString.stringify(
      {
        filters: formatFilters,
        sort: sortBy,
        search: searchValue,
      },
      { arrayFormat: 'separator', arrayFormatSeparator: '|' },
    );

    clearGrowlogList();

    getGrowlogs({
      page: 1,
      search: debounceSearch,
      filters: getFilters(),
      sortBy,
    });
    sendPageView(`/explore-growlogs/${query}`);
    history.push(`/explore-growlogs?${query}`);
  }, [selectedFilters, debounceSearch, sortBy]);


  useEffect(() => {
    if (isFetching && !growlogs.loading && growlogs.totalPages >= growlogs.page + 1) {
      getGrowlogs({
        page: growlogs.page + 1,
        search: debounceSearch,
        filters: getFilters(),
        sortBy,
      });
    }

    setIsFetching(false);
  }, [growlogs.loading, growlogs.page, growlogs.totalPages, isFetching]);

  const getFilterValue = (filter) => {
    const filterOption = filtersOptions
      .reduce((acc, curr) => acc.concat(curr.options), [])
      .find(({ value }) => value.includes(filter));

    return filterOption.value;
  };

  const filtersDTO = (filters) => filters && filters.map((filter) => getFilterValue(filter));

  useEffect(() => {
    const urlQuery = queryString.parse(search, { arrayFormat: 'separator', arrayFormatSeparator: '|' });
    const filters = typeof urlQuery.filters === 'string' ? [getFilterValue(urlQuery.filters)] : filtersDTO(urlQuery.filters);

    if (queryString) {
      setSelectedFilters(filters || []);
      setSortBy(urlQuery.sort);
      setSearchValue(urlQuery.search);
    }
  }, []);

  return (
    <>
      <div className="growlogs-content container">
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          className="margin-bottom-10"
        >
          <h3 className="growlogs-content-title">Explore growlogs</h3>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            className="search-wrapper"
          >
            <Hidden smDown implementation="css">
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {
                  !!sortBy && (
                    <div
                      role="button"
                      tabIndex="0"
                      className="growlogs-content-title-clear"
                      style={{ width: '100%' }}
                      onClick={() => setSortBy(null)}
                      onKeyPress={() => setSortBy(null)}
                    >
                      Clear sort
                      <CloseIcon className="growlogs-content-title-clear-icon" />
                    </div>
                  )
                }
                <Dropdown
                  border={false}
                  name={getLabelSort()}
                  options={sortOptions}
                  handleChange={handleSortBy}
                />
              </Box>
            </Hidden>
            <div className="search">
              <input
                type="text"
                name="search"
                placeholder="Search"
                className="search__input"
                value={searchValue}
                onChange={handleChange}
              />
              {
                debounceSearch
                  ? <CloseIcon onClick={clearSearch} className="search__icon" />
                  : <SearchIcon className="search__icon" />
              }
            </div>
          </Box>
        </Box>
        <Hidden mdUp implementation="css">
          <>
            <Dropdown
              border={false}
              name={getLabelSort()}
              options={sortOptions}
              handleChange={handleSortBy}
            />
            {
              !!sortBy && (
                <div
                  role="button"
                  tabIndex="0"
                  className="growlogs-content-title-clear"
                  style={{ width: '100%' }}
                  onClick={() => setSortBy(null)}
                  onKeyPress={() => setSortBy(null)}
                >
                  Clear sort
                  <CloseIcon className="growlogs-content-title-clear-icon" />
                </div>
              )
            }
          </>
        </Hidden>
        <Filters
          isMobile={isMobile}
          openFilters={openFilters}
          handleOpenFilters={handleOpenFilters}
          handleFilters={handleFilters}
          selectedFilters={selectedFilters}
          clearFilters={clearFilters}
        />
        <Suspense fallback={<h1>Loading…</h1>}>
          {
            growlogs.loading && (growlogs.rows.length <= 0 || debounceSearch)
              ? <Loader />
              : (
                <GrowlogsList
                  growlogs={growlogs}
                  isFetching={isFetching}
                />
              )
          }
        </Suspense>
      </div>
    </>
  );
};

ExploreGrowlogs.propTypes = {
  getGrowlogs: func.isRequired,
  growlogs: shape().isRequired,
  clearGrowlogList: func.isRequired,
  location: shape({}).isRequired,
  history: shape({}).isRequired,
};

export default ExploreGrowlogs;
