export default [
  {
    label: 'About',
    menuOptions: [
      {
        optionlabel: 'About Us',
        to: '/about-us',
      },
    ],
  },
  {
    label: 'Cannabis News',
    menuOptions: [
      {
        optionlabel: 'Growing Cannabis',
        to: '/category/growing-cannabis',
      },
      {
        optionlabel: 'Cannabis Growers',
        to: '/category/cannabis-growers',
      },
      {
        optionlabel: 'Cannabis Science',
        to: '/category/cannabis-science',
      },
    ],
  },
  {
    label: 'Policies',
    menuOptions: [
      {
        optionlabel: 'Privacy Policy',
        to: '/privacy-html',
      },
      {
        optionlabel: 'Cookie Policy',
        to: '/cookie-policy',
      },
      {
        optionlabel: 'Term of use',
        to: '/terms-html',
      },
    ],
  },
  {
    label: 'Get the app',
    menuOptions: [
      {
        optionlabel: 'Google Play Store',
        to: 'https://play.google.com/store/apps/details?id=com.unlogical.jane&referrer=utm_source%3Dwebsite%26utm_medium%3Dfooter%26utm_campaign%3Dlink',
      },
      {
        optionlabel: 'Apple App Store',
        to: 'https://apps.apple.com/app/apple-store/id1467850558?pt=119770304&ct=website-footer-link&mt=8',
      },
    ],
  },
];
