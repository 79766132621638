import React, { useEffect, useState } from 'react';
import {
  Card,
  CardMedia,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Hidden,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import validate from 'validate.js';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '../../components/Button';
import { history } from '../../redux/store';
import janeVertical from '../../assets/img/logo-vertical.png';
import googleDark from '../../assets/img/google-dark.svg';
import register from '../../assets/register.png';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const Register = () => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    // dispatch(login());
    history.push('/');
  };

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values, schema]);

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <div className="register">
      <Card className="register__card">
        <Hidden smDown>
          <CardMedia className="register__card__image" image={register} />
        </Hidden>
        <div className="register__card__content">
          <Hidden smDown>
            <img className="register__card__content__logo" src={janeVertical} alt="logo-vertical" />
          </Hidden>
          <h1>Create your account</h1>
          <Hidden smDown>
            <h5>Create a account with an username and password.</h5>
            <h5>Add a e-mail address to be able to recover it.</h5>
          </Hidden>
          <form className="register__card__content__form" onSubmit={handleSubmit}>
            <TextField className="register__card__content__form__input" label="username" variant="outlined" />
            <TextField className="register__card__content__form__input" label="e-mail (optional)" variant="outlined" />
            <FormControl className="register__card__content__form__input" variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={false ? 'text' : 'password'}
                error={hasError('password')}
                onChange={handleChange}
                endAdornment={(
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                      }}
                      onMouseDown={() => {
                      }}
                      edge="end"
                    >
                      {false ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )}
                labelWidth={70}
              />
            </FormControl>
          </form>
          <Button name="CREATE ACCOUNT" />
          <div className="register__card__content__google-sign-in">
            <p>-- or create a account with --</p>
            <img
              className="register__card__content__google-sign-in__icon"
              src={googleDark}
              alt="google sign in"
            />
          </div>
        </div>
      </Card>
      <div className="register__create-account">
        <h1>Already have a account?</h1>
        <Link to="/login">
          <h5>Sign in to your account</h5>
        </Link>
      </div>
    </div>
  );
};

export default Register;
