import { useState, useEffect } from 'react';

const usePagination = (data, isFetching, setIsFetching) => {
  const growlogEvents = Object.keys(data).reverse();
  const [dataToShow, setEventsToShow] = useState(5);
  const [moreResults, setMoreResults] = useState(true);
  const [events, setEvents] = useState(growlogEvents.slice(0, dataToShow));

  useEffect(() => {
    if (Object.keys(events).length === 0) {
      setEvents(growlogEvents.slice(0, dataToShow));
    }
  }, [data]);

  useEffect(() => {
    const dataLength = Object.keys(data).length;

    if (isFetching && dataLength > dataToShow) {
      setEventsToShow(dataToShow + 5);
      setEvents(growlogEvents.slice(0, dataToShow + 5));
      setIsFetching(false);
    } else if (dataLength <= dataToShow) {
      setIsFetching(false);
      setMoreResults(false);
    }
  }, [isFetching]);

  return [events, moreResults];
};

export default usePagination;
